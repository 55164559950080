import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequisitionSkillsComponent } from './requisition-skills.component';
import { AddEditCandidateSkillModule } from './add-edit-candidate-skill/add-edit-candidate-skill.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';
import { TooltipModule, TypeaheadModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [RequisitionSkillsComponent],
  imports: [
    CommonModule,
    AddEditCandidateSkillModule,
    DragDropModule,
    FormsModule,
    NgxEditorModule,
    TypeaheadModule,
    TooltipModule
  ],
  exports: [RequisitionSkillsComponent],
  entryComponents: [RequisitionSkillsComponent]
})
export class RequisitionSkillsModule { }
