<div class="col-xs-12 pt-1 pb-1" *ngIf="!isLoading">
    <div class="main-box clearfix col-xs-12 mb-0">
        <div class="main-box-body clearfix col-xs-12 px-0">
            <div class="col-xs-12 px-0 pl-4 pt-2 mt-4 mb-5 d-flex justify-content-between align-items-center">
                <div>
                    <h2 class="requisition-description-heading font-bold">Job Description</h2>
                </div>
                <div class="pull-right pr-4 mt-2" *ngIf="currentJobStatus !== 'DRAFT'">
                    <div class="btn-group revert-dropdown">
                        <button type="button" class="btn btn-danger" data-toggle="dropdown">Revert to</button>
                        <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <span class="caret"></span>
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu">
                            <li>
                                <a class="pl-4" (click)="revertToJobDescriptionFile()">
                                    <span class="pl-3">Job description file</span>
                                </a>
                            </li>
                            <li>
                                <a class="pl-4" (click)="revertToSavedChanges()">
                                    <span class="pl-3">Saved changes</span>
                                </a>
                            </li>
                            <li>
                                <a class="pl-4" (click)="revertToOriginalActivatedVersion()">
                                    <span class="pl-3">Original Activated Version</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 mb-3 pl-4">
                <div class="col-xs-12 pl-0">
                    <div class="col-xs-5 pl-0">
                        <!-- job title -->
                        <div class="form-group">
                            <label>Title <em>*</em> : </label>
                            <a *ngIf="currentJobStatus !== 'DRAFT'" class="pl-2" (click)="showJobProfile()" style="color: black; font-size: 100%; font-weight: bold;" tooltip="{{jobTitle}}">
                                <span class="control-label">{{jobTitle}}</span>
                            </a>
                            <i class="fas fa-pencil-alt cursor-pointer" tooltip="Edit Title" (click)="editTitleFlag = !editTitleFlag" *ngIf="isUploadRequisition"></i>
                            <input *ngIf="(currentJobStatus == 'DRAFT') && (editTitleFlag || !isUploadRequisition)" type="text" class="form-control" id="jobTitle" [(ngModel)]="jobTitle"
                                    name="jobTitle" style="border-width: 1px;width: 100%">
                        </div>
                    </div>
                    <div class="col-xs-5">
                        <!-- Location -->
                        <div class="form-group">
                            <label>Location : </label>
                            <span tooltip="{{jobLocation}}" style="font-size: 12px; font-weight: bold; cursor:pointer;">
                                {{jobLocation}}
                            </span>
                            <i class="fas fa-pencil-alt cursor-pointer" tooltip="Edit Location" (click)="updateLocation()"></i>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xs-12 px-0 pl-4 pr-5 d-flex align-items-end">
                <div class="col-xs-6 px-0">
                    <div class="col-xs-12 p-3 skill-category-container">
                        <div class="col-xs-12 pl-0" [ngClass]="{'pb-3': addAllSkills != ''}">
                            <div class="col-xs-12 pt-2">
                                <div style="display: inline;" class="mr-3 font-bold">Quick Add:</div>
                                <div class="upload-req-radio mr-2" style="display: inline;">
                                    <input type="radio" name="addAllSkills" id="addEducation" value="education"
                                        [(ngModel)]="addAllSkills" (change)="onAllSkillsChanges()">
                                    <label for="addEducation" class="mb-0">
                                        Education
                                    </label>
                                </div>
                                <div class="upload-req-radio mr-2" style="display: inline;">
                                    <input type="radio" name="addAllSkills" id="addExperience" value="experience"
                                        [(ngModel)]="addAllSkills" (change)="onAllSkillsChanges()">
                                    <label for="addExperience" class="mb-0">
                                        Experience
                                    </label>
                                </div>
                                <div class="upload-req-radio" style="display: inline;">
                                    <input type="radio" name="addAllSkills" id="addSkills" value="skill"
                                        [(ngModel)]="addAllSkills" (change)="onAllSkillsChanges()">
                                    <label for="addSkills" class="mb-0">
                                        Skills
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 px-0" [ngClass]="{'pb-2': addAllSkills != ''}">
                            <div class="col-xs-12 pr-0 skills-container d-flex align-items-center">
                                <div *ngIf="addAllSkills === 'education'"
                                    class="d-flex justify-content-between mt-2 pl-0 col-xs-6">
                                    <input 
                                        [(ngModel)]="newRequisitionSkill.competency" 
                                        [typeahead]="allRequisitionSkillsObservable"
                                        [typeaheadAsync]="true"
                                        typeaheadOptionField="displayName" 
                                        class="d-inline-block" 
                                        placeholder="Enter Education"
                                        (typeaheadOnSelect)="onRequisitionSkillSelect($event)" 
                                        style="font-size: 15px; width: 100%;"
                                        [typeaheadOptionsLimit]="8"
                                        container="body"
                                        [typeaheadScrollable]="true"
                                        [typeaheadOptionsInScrollableView]="8"
                                    >
                                </div>
                                <div *ngIf="addAllSkills === 'experience'"
                                    class="d-flex justify-content-between mt-2 pl-0 col-xs-6">
                                    <input 
                                        [(ngModel)]="newRequisitionSkill.competency" 
                                        [typeahead]="allRequisitionSkillsObservable"
                                        [typeaheadAsync]="true"
                                        typeaheadOptionField="displayName" 
                                        class="d-inline-block" 
                                        placeholder="Enter Experience"
                                        (typeaheadOnSelect)="onRequisitionSkillSelect($event)" 
                                        style="font-size: 15px; width: 100%;"
                                        [typeaheadOptionsLimit]="8"
                                        container="body"
                                        [typeaheadScrollable]="true"
                                        [typeaheadOptionsInScrollableView]="8"
                                    >
                                </div>
                                <div *ngIf="addAllSkills === 'skill'"
                                    class="d-flex justify-content-between mt-2 pl-0 col-xs-6">
                                    <input 
                                        [(ngModel)]="newRequisitionSkill.competency" 
                                        [typeahead]="allRequisitionSkillsObservable"
                                        [typeaheadAsync]="true"
                                        typeaheadOptionField="displayName" 
                                        class="d-inline-block" 
                                        placeholder="Enter Skill"
                                        (typeaheadOnSelect)="onRequisitionSkillSelect($event)" 
                                        style="font-size: 15px; width: 100%;"
                                        [typeaheadOptionsLimit]="8"
                                        container="body"
                                        [typeaheadScrollable]="true"
                                        [typeaheadOptionsInScrollableView]="8"
                                    >
                                </div>
                                <div class="px-3 py-2 position-relative" *ngIf="addAllSkills && addAllSkills != ''">
                                    <button class="btn btn-primary" (click)="addNewRequisitionSkill(addAllSkills)">Add
                                        {{addAllSkills.slice(0,1).toUpperCase() + addAllSkills.slice(1)}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        
            <div class="col-xs-12 mt-3" cdkDropListGroup>
                <div class="col-xs-12 my-2">
                    <!-- Education -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container mr-2 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.educations" skillType="education" context="job"
                            mode="edit" [allExistingDegrees]="allExistingDegrees" [allExistingFieldOfStudy]="allExistingFieldOfStudy"
                            [allUpdatedDegrees]="allUpdatedDegrees" [allUpdatedFieldOfStudy]="allUpdatedFieldOfStudy" [timePeriods]="timePeriods"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                    <!-- Experience -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container ml-3 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.experiences" [allExistingSkills]="allExistingSkills"
                                (saveCallback)="skillsSaveCallback($event)" skillType="experience" context="job"
                                mode="edit" [allExistingRoles]="allExistingRoles" [allUpdatedRoles]="allUpdatedRoles" [timePeriods]="timePeriods"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 my-3">
                    <!-- Technical skills -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container mr-2 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.technicalSkills" [allExistingSkills]="allExistingSkills"
                                (saveCallback)="skillsSaveCallback($event)" skillType="technical" context="job"
                                mode="edit" [allExistingJDActionWords]="allExistingJDActionWords" [allUpdatedJDActionWords]="allUpdatedJDActionWords" 
                                [timePeriods]="timePeriods" (setOtherSkillsCallback)="addSkillsToOther($event, 'technicalSkills')"
                                (setSkillsAsPerCategoryCallback)="addSkillsAsPerCategory($event, 'technicalSkills')"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                    <!-- Operational Skills -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container ml-3 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.operationalSkills"
                                [allExistingSkills]="allExistingSkills" (saveCallback)="skillsSaveCallback($event)"
                                skillType="operational" context="job" mode="edit"
                                [allExistingJDActionWords]="allExistingJDActionWords" [allUpdatedJDActionWords]="allUpdatedJDActionWords"
                                [timePeriods]="timePeriods" (setOtherSkillsCallback)="addSkillsToOther($event, 'operationalSkills')"
                                (setSkillsAsPerCategoryCallback)="addSkillsAsPerCategory($event, 'operationalSkills')"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 my-2">
                    <!-- Soft Skills -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container mr-2 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.softSkills" [allExistingSkills]="allExistingSkills"
                                (saveCallback)="skillsSaveCallback($event)" skillType="soft" context="job"
                                mode="edit" [allExistingJDActionWords]="allExistingJDActionWords" [allUpdatedJDActionWords]="allUpdatedJDActionWords"
                                [timePeriods]="timePeriods" (setOtherSkillsCallback)="addSkillsToOther($event, 'softSkills')"
                                (setSkillsAsPerCategoryCallback)="addSkillsAsPerCategory($event, 'softSkills')"></app-add-edit-candidate-skill>
                        </div>
                    </div>
        
                    <!-- Other Skills -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container ml-3 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.extraSkills" [allExistingSkills]="allExistingSkills"
                                (saveCallback)="skillsSaveCallback($event)" skillType="other" context="job"
                                mode="edit" [allExistingJDActionWords]="allExistingJDActionWords" [allUpdatedJDActionWords]="allUpdatedJDActionWords"
                                [timePeriods]="timePeriods" (setSkillsAsPerCategoryCallback)="addSkillsAsPerCategory($event, 'otherSkills')"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 my-3">
                    <!-- Certifications -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container mr-2 py-4 px-3">
                            <app-add-edit-candidate-skill 
                                [skills]="requisitionSkills.certifications" 
                                skillType="certification" 
                                context="job"
                                mode="edit" 
                                [allExistingCertificates]="allExistingCertificates"
                                [allUpdatedCertificates]="allUpdatedCertificates"
                                [timePeriods]="timePeriods"
                                >
                            </app-add-edit-candidate-skill>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-xs-12 pt-4" *ngIf="!isLoading">
    <div class="main-box clearfix col-xs-12">
        <div class="main-box-body clearfix col-xs-12 px-4">
            <h2 class="requisition-description-heading my-4 pb-1 font-bold">Benefits</h2>
            <!-- Benefits note -->
            <div class="form-group mb-0">
                <app-ngx-editor [placeholder]="'Enter text here...'" [spellcheck]="true" [(ngModel)]="benefitsNote"
                    [config]="editorConfig">
                </app-ngx-editor>
                <!-- <div class="error error-gap" *ngIf="!benefitsNotes">
                <small class=""><em>*</em>
                    External Note is required
                </small>
            </div> -->
            </div>
        </div>
    </div>
</div>

<div class="main-box-body clearfix col-lg-12" *ngIf="isLoading">
    <div class="text-center" style="font-size: 20px;">
        <span class="pr-2">Loading</span>
        <span class="fa fa-spinner fa-spin"></span>
    </div>
</div>
