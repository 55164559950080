import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { JobService } from 'src/app/core/services/job.service';
import {
  CdkDragDrop,
  CdkDrag,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';
import { NewRequisitionSkills } from 'src/app/core/models/newRequistionSkills';
import { range } from 'lodash';
import { RequisitionSkill } from 'src/app/core/models/requisitionSkill';

@Component({
  selector: 'app-requisition-skills',
  templateUrl: './requisition-skills.component.html',
  styleUrls: ['./requisition-skills.component.css']
})
export class RequisitionSkillsComponent implements OnInit {

  @Input() currentJobStatus;
  @Input() isUploadRequisition;
  @Input() jobTitle;
  @Input() jobLocation;
  @Output() showJobProfileCallback =  new EventEmitter<any>();
  @Output() updateLocationCallback = new EventEmitter<any>();

  allExistingSkills: any = [];
  requisitionSkills: any = [];
  benefitsNotes: any = '';
  editorConfig: any;
  addAllSkills: any = 'education';
  newRequisitionSkill: any = {};
  allEducationsList: any = [];
  educationDepartmentList: any = [];
  experiencesList: any = [];
  certificationsList: any = [];
  allSkillsList: any = [];
  ranges: any = [];
  allRequisitionSkillsObservable: Observable<any>;
  timePeriods: any = [];
  skillsTimePeriods: any = [];
  searchSkills: any = [];
  allExistingDegrees: any = [];
  allUpdatedDegrees: any = [];
  allExistingFieldOfStudy: any = [];
  allUpdatedFieldOfStudy: any = [];
  allExistingRoles: any = [];
  allUpdatedRoles: any = [];
  allExistingCertificates: any = [];
  allUpdatedCertificates: any = [];
  allExistingJDActionWords: any = [];
  allUpdatedJDActionWords: any = [];


  isLoading: boolean = true;

  constructor(
    private jobService: JobService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private skillsService: ManageSkillsService
  ) { 
    this.allRequisitionSkillsObservable = Observable.create((observer: any) => {
      // Runs on every search 
      observer.next(this.newRequisitionSkill.competency);
    })
      .pipe(
        mergeMap((token: string) => this.getStatesAsObservable(token))
      );
  }

  ngOnInit() {
    this.editorConfig = {
      toolbar: [
        ['bold', 'italic', 'h1', 'h2', 'h3'],
        ['underline', 'strikeThrough'],
        ['fontSize'],
        ['orderedList', 'unorderedList'],
        ['undo', 'redo'],
        ['paragraph'],
        ['blockquote'],
        ['removeFormat']
      ]
    };
    this.requisitionSkills = new NewRequisitionSkills();
    this.getAllSkills(() => {
      this.getDegreesEntities(() => {
        this.getFieldOfStudyEntities(() => {
          this.getRoles(() => {
            this.getCertificates(() => {
              this.getJdActions(() => {
                this.setAllRequisitionSkills();
                this.setTimePeriodsForTypeAhead();
                this.isLoading = false;
              });
            });
          });
        });
      });
    });
  }

  setTimePeriodsForTypeAhead() {
    this.timePeriods = this.skillsService.setTimePeriodsInRequisitionSkillsTypeAhead();
    this.setJDActionWords();
  }

  setJDActionWords() {
    this.skillsTimePeriods = _.cloneDeep(this.timePeriods);
    this.allUpdatedJDActionWords.forEach(actionWord => {
      this.skillsTimePeriods.push({name: actionWord.name + " ", value: {fromYear: "", toYear: "", months: 0, range: "",plusTo: "",isJDActionWord: true, jdAction: actionWord}});
    });
  }

  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
}

  getStatesAsObservable(token: string): Observable<any> {
    const escapedToken = this.escapeRegExp(token);
    const query = new RegExp(escapedToken, 'i');
    this.searchSkills = [];
    if(this.addAllSkills === 'education') {
      this.searchSkills = this.getEducationTypeAhead(token);
    } else if(this.addAllSkills === 'experience') {
      this.searchSkills = this.getExperienceTypeAhead(token);
    } else {
      this.searchSkills = this.getSkill(token);
    }
    return of(
      this.searchSkills.filter((state: any) => {
        return query.test(state.name);
      })
    );
  }

  _getYearsForSearchString(searchText, type) {
    let yearsResult = []
    if(type === 'skill') {
      yearsResult = this.skillsTimePeriods.filter((period) => {
        return period.name.toLowerCase().includes(searchText);
      });
    } else {
      yearsResult = this.timePeriods.filter((period) => {
        return period.name.toLowerCase().includes(searchText);
      });
    }
    let yearsObjectArray = [];
    angular.forEach(yearsResult, (year, key) => {
      yearsObjectArray.push({ displayName: year.name, name: year.name });
    });
    return yearsObjectArray;
  }

  getSkill(searchText) {
    let searchResults = [];
    searchText = searchText.toLowerCase();

    if (this._searchStringHasYearsInfoButNoSkill(searchText, 'skill')) {
      return this._getYearsForSearchString(searchText, 'skill');
    }

    let periodObject;
    let preText = "";
    let isSearchStringHasYears = false;
    let actualSearchText = "";
    angular.forEach(this.skillsTimePeriods, (period, key) => {
      if (searchText.includes(period.name.toLowerCase())) {
        isSearchStringHasYears = true;
        preText = period.name;
        actualSearchText = "";
        actualSearchText = searchText.replace(preText.toLowerCase(), "");
        periodObject = angular.copy(period);
      }
    });

    if (!isSearchStringHasYears) {
      actualSearchText = searchText;
    }

    let validSkills = this.allExistingSkills.filter((skill) => {
      return skill && skill.skill.toLowerCase().includes(actualSearchText);
    });

    if (isSearchStringHasYears && validSkills.length > 0) {
      angular.forEach(validSkills, (skill, key) => {
        searchResults.push({ displayName: preText + skill.skill, name: preText + skill.skill, experience: periodObject.value, mandatory: false, favorite: false, id: skill.id, skill: skill });
      });
    } else if (validSkills.length > 0) {
      angular.forEach(validSkills, (skill, key) => {
        searchResults.push({ displayName: skill.skill, name: skill.skill, experience: skill.experience, mandatory: false, favorite: false, id: skill.id, skill: skill });
      });
    }
    return searchResults;
  }

  _searchStringHasYearsInfoButNoSkill(searchText, type) {
    let yearsResult = [];
    if(type === 'skill') {
      yearsResult = this.skillsTimePeriods.filter((period) => {
        return period.name.toLowerCase().includes(searchText);
      });
    } else {
      yearsResult = this.timePeriods.filter((period) => {
        return period.name.toLowerCase().includes(searchText);
      });
    }
    return yearsResult.length > 0;
  }

  _searchStringHasDegreesInfoButNoFieldOfStudy(searchText) {
    let degreesResult = this.allUpdatedDegrees.filter((degree) => {
      return degree.degreeName.toLowerCase().includes(searchText);
    });
    return degreesResult.length > 0;
  }

  _getDegreesForSearchString(searchText) {
    let degreesResult = this.allUpdatedDegrees.filter((degree) => {
      return degree.degreeName.toLowerCase().includes(searchText);
    });
    let degreesObjectArray = [];
    degreesResult.forEach(degree => {
      degreesObjectArray.push({ displayName: degree.name, name: degree.name});
    })
    return degreesObjectArray;
  }

  getEducationTypeAhead(searchText) {
    let searchResults = [];
    searchText = searchText.toLowerCase();

    if (this._searchStringHasDegreesInfoButNoFieldOfStudy(searchText)) {
      return this._getDegreesForSearchString(searchText);
    }

    let searchDegreeObj;
    let preText = "";
    let isSearchStringHasDegrees = false;
    let actualSearchText = "";
    this.allUpdatedDegrees.forEach(degree => {
      if(searchText.includes(degree.degreeName.toLowerCase())) {
        isSearchStringHasDegrees = true;
        preText = degree.degreeName;
        actualSearchText = "";
        actualSearchText = searchText.replace(preText.toLowerCase(), "");
        if(actualSearchText.includes(" in ")) {
          actualSearchText = actualSearchText.replace(" in ", "");
        } else if(actualSearchText.includes(" in")) {
          actualSearchText = actualSearchText.replace(" in", "");
        }
        searchDegreeObj = _.cloneDeep(degree);
        isSearchStringHasDegrees = true;
      }
    });

    if (!isSearchStringHasDegrees) {
      actualSearchText = searchText;
    }

    let validFieldOfStudy = this.allUpdatedFieldOfStudy.filter((fieldOfStudy) => {
      return fieldOfStudy.fieldOfStudy.toLowerCase().includes(actualSearchText);
    });

    if (isSearchStringHasDegrees && validFieldOfStudy.length > 0) {
      validFieldOfStudy.forEach(fieldOfStudy => {
          searchResults.push(
            { 
              displayName: searchDegreeObj.name + fieldOfStudy.fieldOfStudy, 
              name: searchDegreeObj.name + fieldOfStudy.fieldOfStudy, 
              experience: 0, 
              mandatory: false, 
              favorite: false, 
              id: fieldOfStudy.id, 
              degreeName: searchDegreeObj.degreeName, 
              fieldOfStudy: fieldOfStudy.fieldOfStudy 
            });
      });
    } else {
      validFieldOfStudy.forEach(fieldOfStudy => {
        searchResults.push(
          { 
            displayName: fieldOfStudy.fieldOfStudy, 
            name: fieldOfStudy.fieldOfStudy, 
            experience: 0, 
            mandatory: false, 
            favorite: false, 
            id: fieldOfStudy.id,
            degreeName: "", 
            fieldOfStudy: fieldOfStudy.fieldOfStudy 
          });
    });
    }
    return searchResults;
  }


  getExperienceTypeAhead(searchText) {
    let searchResults = [];
    searchText = searchText.toLowerCase();

    if (this._searchStringHasYearsInfoButNoSkill(searchText, 'role')) {
      return this._getYearsForSearchString(searchText, 'role');
    }

    let periodObject;
    let preText = "";
    let isSearchStringHasYears = false;
    let actualSearchText = "";
    angular.forEach(this.timePeriods, (period, key) => {
      if (searchText.includes(period.name.toLowerCase())) {
        isSearchStringHasYears = true;
        preText = period.name;
        actualSearchText = "";
        actualSearchText = searchText.replace(preText.toLowerCase(), "");
        periodObject = angular.copy(period);
      }
    });

    if (!isSearchStringHasYears) {
      actualSearchText = searchText;
    }

    let validRoles = this.allUpdatedRoles.filter((role) => {
      return role.role.toLowerCase().includes(actualSearchText);
    });

    if (isSearchStringHasYears && validRoles.length > 0) {
      validRoles.forEach(role => {
        searchResults.push(
          { displayName: preText + role.role, 
            name: preText + role.role, 
            experience: periodObject.value, 
            mandatory: false, 
            favorite: false, 
            id: role.role.id, 
            title: role.role,
            role: role 
          });
      });
    } else if (validRoles.length > 0) {
      validRoles.forEach(role => {
        searchResults.push(
          { displayName: role.role, 
            name: role.role, 
            experience: 0, 
            mandatory: false, 
            favorite: false, 
            id: role.role.id, 
            title: role.role,
            role: role
          });
      });
    }
    return searchResults;
  }

  setAllRequisitionSkills() {
    
    this.ranges = [
      { name: "", value: "" },
      { name: "Atleast", value: "atleast" },
      { name: "Min", value: "min" },
      { name: "Max", value: "max" }
    ];
    this.newRequisitionSkill = new RequisitionSkill();
  }

  getAllSkills(successCallback) {
    let filterObject = {
      searchSkill: '',
      searchByParentSkill: '',
      searchCategory: '',
      sortColumn: 'skill',
      sortDir: 'ASC',
      includeAlternateSkills: false
    };

    this.allExistingSkills = [];
    this.jobService.getAllSkills(filterObject, (data) => {
      this.allExistingSkills = data ? data.allSkills.filter(skill => skill) : [];
      this.allSkillsList = [...this.allExistingSkills];
      this.allExistingSkills.forEach(skill => {
        skill.competency = skill.skill;
      });
      if(successCallback) {
        successCallback();
      }
    }, error => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getDegreesEntities(successCallback) {
    let degreeFilerObj = {
      searchDegree: '',
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllDegreesEntityLists(degreeFilerObj, (data) => {
      this.allUpdatedDegrees = data ? data.allEntities : [];
      this.allExistingDegrees = [...this.allUpdatedDegrees];
      let equivalentDegreeList = [];
      this.allUpdatedDegrees.forEach(degree => {
        let tempDegree = JSON.parse(JSON.stringify(degree));
        tempDegree.competency = degree.degreeName + " or equivalent experience";
        tempDegree.name = degree.degreeName + " or equivalent experience";
        tempDegree.degreeName = degree.degreeName + " or equivalent experience";
        equivalentDegreeList.push(tempDegree);
        degree.competency = degree.degreeName;
        degree.name = degree.degreeName + " in ";
      });
      this.allUpdatedDegrees = [...this.allUpdatedDegrees, ...equivalentDegreeList];
      if(successCallback) {
        successCallback()
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    })
  }

  getFieldOfStudyEntities(successCallback) {
    let fieldOfStudyObj = {
      searchFieldOfStudy: '',
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllFieldOfStudyLists(fieldOfStudyObj, (data) => {
      this.allUpdatedFieldOfStudy = data ? data.allEntities : [];
      this.allExistingFieldOfStudy = [...this.allUpdatedFieldOfStudy];
      this.allUpdatedFieldOfStudy.forEach(fieldOfStudy => {
        fieldOfStudy.competency = fieldOfStudy.fieldOfStudy;
      });
      if(successCallback) {
        successCallback()
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getRoles(successCallback) {
    let roleObj = {
      searchRole: '',
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllCandidateRolesLists(roleObj, (data) => {
      this.allUpdatedRoles = data ? data.allEntities : [];
      this.allExistingRoles = [...this.allUpdatedRoles];
      this.allUpdatedRoles.forEach(role => {
        role.competency = role.role;
      });
      if(successCallback) {
        successCallback()
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getCertificates(successCallback) {
    let certificatesObj = {
      searchName: '',
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllCertificationsLists(certificatesObj, (data) => {
      this.allUpdatedCertificates = data ? data.allEntities : [];
      this.allExistingCertificates = [...this.allUpdatedCertificates];
      this.allUpdatedCertificates.forEach(certificate => {
        certificate.competency = certificate.name;
        certificate.inName = certificate.group + " in "
      });
      if(successCallback) {
        successCallback()
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getJdActions(successCallback) {
    let jdActionsObj = {
      searchName: '',
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllJdActionsLists(jdActionsObj, (data) => {
      this.allExistingJDActionWords = data ? data.allEntities : []; 
      this.allExistingJDActionWords.forEach(jdAction => {
        if(jdAction.jobDescriptionLanguageDropdown && jdAction.jobDescriptionLanguageDropdown.length > 0) {
          jdAction.jobDescriptionLanguageDropdown.forEach(actionWord => {
            let actionWordTemp:any = {name: actionWord, jdAction: jdAction};
            this.allUpdatedJDActionWords.push(actionWordTemp);
          });
        }
      });
      if(successCallback) {
        successCallback()
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }


  skillsSaveCallback(event) {
    if (event && event.length > 0) {
      this.allExistingSkills = event;
    }
  }

  onBenefitsNoteChanged() {

  }

  revertToJobDescriptionFile() {
    let message = "<p>Are you sure?</p><p>All your changes will be lost and will be put back to the uploaded Job Description.</p>";
    this.alertsAndNotificationsService.showConfirm("Revert to Job description file", message, "warning", true, () => {
      // Api for reverting changes to last saved version
      this.alertsAndNotificationsService.showBannerMessage('Changes reverted to uploaded Job Description successfully', 'success');
    }, () => {
      //do nothing  
    });
  }

  revertToSavedChanges() {
    let message = "<p>Are you sure?</p><p>All your changes will be lost and will be put back to the previously saved information.</p>";
    this.alertsAndNotificationsService.showConfirm("Revert to Saved changes", message, "warning", true, () => {
      // Api for reverting changes to last saved version
      this.alertsAndNotificationsService.showBannerMessage('Changes reverted to saved information successfully', 'success');
    }, () => {
      //do nothing  
    });
  }

  revertToOriginalActivatedVersion() {
    let message = "<p>Are you sure?</p><p>Any changes done since the requisition was originally activated, including versions saved after that and any current changes will be lost. You be returned to the original criteria at the time the Requisition was <b>Activated.</b></p><p>This change cannot be <b>undone.</b></p>";
    this.alertsAndNotificationsService.showConfirm("Revert to Original Activated Version", message, "warning", true, () => {
      // Api for reverting changes to last saved version
      this.alertsAndNotificationsService.showBannerMessage('Changes reverted to the original criteria at the time the Requisition was <b>Activated.</b> successfully', 'success');
    }, () => {
      //do nothing  
    });
  }

  onAllSkillsChanges() {
    this.newRequisitionSkill = new RequisitionSkill();
  }

  updateMandatory(skill) {
    skill.mandatory = !skill.mandatory;
    if (skill.mandatory) {
      skill.niceToHave = false;
    }
  }

  updateNiceToHave(skill) {
    skill.niceToHave = !skill.niceToHave;
    if (skill.niceToHave) {
      skill.mandatory = false;
    }
  }

  addNewRole(role) {
    const filteredSearchRole = this.searchSkills.filter(searchRole => searchRole.displayName === role);
    let filteredRole;
    if (filteredSearchRole.length > 0 && filteredSearchRole[0].role) {
      filteredRole = this.allUpdatedRoles.find(val => val.role === filteredSearchRole[0].role.role);
      this.newRequisitionSkill.fromYear = filteredSearchRole[0].experience ? filteredSearchRole[0].experience.fromYear : "";
      this.newRequisitionSkill.toYear = (filteredSearchRole[0].experience && filteredSearchRole[0].experience.toYear != '') ? filteredSearchRole[0].experience.toYear : "";
      this.newRequisitionSkill.atleastOrMinOrMax = filteredSearchRole[0].experience ? filteredSearchRole[0].experience.range : ""
      this.newRequisitionSkill.title = filteredSearchRole[0].title;
      this.newRequisitionSkill.plusTo = filteredSearchRole[0].experience ? filteredSearchRole[0].experience.plusTo : "";
    } else {
      let typeAheadDropDownTextObj = this.timePeriods.filter(searchText => searchText.name === role)[0];
      if (typeAheadDropDownTextObj) {
        this.newRequisitionSkill.competency = "";
        this.alertsAndNotificationsService.showAlert('Missing Details', "Please add 'Role", 'warning');
      } else {
        let typeAheadDropDownTextObj = this.timePeriods.filter(searchText => (searchText.name.length < role.length) && role.includes(searchText.name))[0];
        if (!typeAheadDropDownTextObj) {
          this.newRequisitionSkill.competency = "";
          this.alertsAndNotificationsService.showAlert('Missing Details', "Please add 'Role", 'warning');
        } else {
          let typeAheadValue = typeAheadDropDownTextObj.value;
          this.newRequisitionSkill.fromYear = typeAheadValue.fromYear;
          this.newRequisitionSkill.toYear = typeAheadValue.toYear;
          this.newRequisitionSkill.atleastOrMinOrMax = typeAheadValue.range;
          this.newRequisitionSkill.plusTo = typeAheadValue.plusTo;
          this.newRequisitionSkill.competency = role.replace(typeAheadDropDownTextObj.name, "");
        }
      }
    }
    this.newRequisitionSkill.displayName = role;
    this.requisitionSkills.experiences = [...this.requisitionSkills.experiences, ...[this.newRequisitionSkill]];
    return;
  }

  addNewEducation(education, newSkillTemp) {
    newSkillTemp.displayName = newSkillTemp.competency;
    if (this.newRequisitionSkill.degreeName != '' && this.newRequisitionSkill.fieldOfStudy != '') {
      if(this.newRequisitionSkill.competency == (this.newRequisitionSkill.degreeName + " in " + this.newRequisitionSkill.fieldOfStudy)) {
        this.requisitionSkills.educations = [...this.requisitionSkills.educations, ...[newSkillTemp]];
      }
    } else if(this.newRequisitionSkill.competency.includes(" in ")) {
      let degreesAndFieldOfStudy = this.newRequisitionSkill.competency.split(" in ");
      newSkillTemp.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
      newSkillTemp.fieldOfStudy = degreesAndFieldOfStudy[1] ? degreesAndFieldOfStudy[1] : "";
      this.requisitionSkills.educations = [...this.requisitionSkills.educations, ...[newSkillTemp]];
    } else if(this.newRequisitionSkill.competency.includes(" in")) {
      let degreesAndFieldOfStudy = this.newRequisitionSkill.competency.split(" in");
      newSkillTemp.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
      newSkillTemp.fieldOfStudy = "";
      this.requisitionSkills.educations = [...this.requisitionSkills.educations, ...[newSkillTemp]];
    } else {
      newSkillTemp.degreeName = newSkillTemp.competency;
      this.requisitionSkills.educations = [...this.requisitionSkills.educations, ...[newSkillTemp]];
    } 
  }

  addNewSkillBySkillCategory(type, skill) {
    const filteredSearchSkill = this.searchSkills.filter(searchSkill => searchSkill.displayName === skill);
    let filteredSkill;
    if (filteredSearchSkill.length > 0 && filteredSearchSkill[0].skill) {
      filteredSkill = this.allSkillsList.find(val => val.skill === filteredSearchSkill[0].skill.skill);
      this.newRequisitionSkill.fromYear = filteredSearchSkill[0].experience ? filteredSearchSkill[0].experience.fromYear : "";
      this.newRequisitionSkill.toYear = (filteredSearchSkill[0].experience && filteredSearchSkill[0].experience.toYear != '') ? filteredSearchSkill[0].experience.toYear : "";
      this.newRequisitionSkill.atleastOrMinOrMax = filteredSearchSkill[0].experience ? filteredSearchSkill[0].experience.range : ""
      this.newRequisitionSkill.competency = filteredSearchSkill[0].skill.skill;
      this.newRequisitionSkill.plusTo = filteredSearchSkill[0].experience ? filteredSearchSkill[0].experience.plusTo : "";
      this.newRequisitionSkill.jdActionWord = (filteredSearchSkill[0].experience && filteredSearchSkill[0].experience.isJDActionWord) ? filteredSearchSkill[0].experience.jdAction.name : "";
    } else {
      let typeAheadDropDownTextObj = this.timePeriods.filter(searchText => searchText.name === skill)[0];
      if (typeAheadDropDownTextObj) {
        this.newRequisitionSkill.competency = "";
        this.alertsAndNotificationsService.showAlert('Missing Details', "Please add 'Skill", 'warning');
      } else {
        let typeAheadDropDownTextObj = this.timePeriods.filter(searchText => (searchText.name.length < skill.length) && skill.includes(searchText.name))[0];
        if (!typeAheadDropDownTextObj) {
          this.newRequisitionSkill.competency = "";
          this.alertsAndNotificationsService.showAlert('Missing Details', "Please add 'Skill", 'warning');
        } else {
          let typeAheadValue = typeAheadDropDownTextObj.value;
          this.newRequisitionSkill.fromYear = typeAheadValue.fromYear;
          this.newRequisitionSkill.toYear = typeAheadValue.toYear;
          this.newRequisitionSkill.atleastOrMinOrMax = typeAheadValue.range;
          this.newRequisitionSkill.plusTo = typeAheadValue.plusTo;
          this.newRequisitionSkill.competency = skill.replace(typeAheadDropDownTextObj.name, "");
          this.newRequisitionSkill.jdActionWord = (filteredSearchSkill[0].experience && filteredSearchSkill[0].experience.isJDActionWord) ? filteredSearchSkill[0].experience.jdAction.name : "";
        }
      }
    }
    this.newRequisitionSkill.displayName = skill;

    if (!filteredSkill) {
      if(this.newRequisitionSkill.competency != "") {
        this.requisitionSkills.extraSkills = [...this.requisitionSkills.extraSkills, ...[this.newRequisitionSkill]];
      }
      return;
    }

    switch (filteredSkill.category.toLowerCase()) {
      case 'technical':
        this.requisitionSkills.technicalSkills = [...this.requisitionSkills.technicalSkills, ...[this.newRequisitionSkill]];
        break;
      case 'operational':
        this.requisitionSkills.operationalSkills = [...this.requisitionSkills.operationalSkills, ...[this.newRequisitionSkill]];
        break;
      case 'soft':
        this.requisitionSkills.softSkills = [...this.requisitionSkills.softSkills, ...[this.newRequisitionSkill]];
        break;
      default:
        this.requisitionSkills.extraSkills = [...this.requisitionSkills.extraSkills, ...[this.newRequisitionSkill]];
        break;
    }

    if (this.allExistingSkills) {
      this.allExistingSkills = this.allExistingSkills.filter(skill => skill.skill != this.newRequisitionSkill.competency);
    }
  }

  addNewRequisitionSkill(type) {
    let newSkillTemp = { ...this.newRequisitionSkill };
    let alertMessage = '';

    switch (type) {
      case 'education':
        if (this.newRequisitionSkill.competency != '') {
          this.addNewEducation(this.newRequisitionSkill.competency, newSkillTemp);
        } else {
          alertMessage = "Please add 'Education'.";
        }
        break;
      case 'experience':
        if (this.newRequisitionSkill.competency != '') {
          this.addNewRole(this.newRequisitionSkill.competency);
        } else {
          alertMessage = "Please add 'Experience'.";
        }
        break;
      case 'skill':
        if (this.newRequisitionSkill.competency != '') {
          this.addNewSkillBySkillCategory(type, this.newRequisitionSkill.competency);
        } else {
          alertMessage = "Please add 'Skill'.";
        }
        break;
      default:
        break;
    }

    if (alertMessage) {
      this.alertsAndNotificationsService.showAlert('Missing Details', alertMessage, 'warning');
    } else {
      this.newRequisitionSkill = new RequisitionSkill();
    }

  }

  onRequisitionSkillSelect(e: TypeaheadMatch): void {
    let event:any = e;
    if(this.addAllSkills === 'education') {
      this.newRequisitionSkill.degreeName = event.item.degreeName ? event.item.degreeName : "";
      this.newRequisitionSkill.fieldOfStudy = event.item.fieldOfStudy ? event.item.fieldOfStudy : "";
    } else if(this.addAllSkills === 'experience') {
      this.newRequisitionSkill.title = event.title;
    }
  }

  showJobProfile() {
    if(this.showJobProfileCallback) {
      this.showJobProfileCallback.emit();
    }
  }

  updateLocation() {
    if(this.updateLocationCallback) {
      this.updateLocationCallback.emit();
    }
  }

  addSkillsToOther(event, type) {
    if (event) {
      this.requisitionSkills.extraSkills.push(event);
      let title = "Skill Moved";
      let message = `<b>'${event.skill.skill}'</b> has been moved under <b>'Other'</b>`;
      this.alertsAndNotificationsService.showAlert(title, message, 'warning');
    }
  }

  addSkillsAsPerCategory(event, type) {
    let title = "Skill Moved";
    let categoryHeading = event.skill.category.charAt(0).toUpperCase() + event.skill.category.substring(1).toLowerCase();
    let message = `<b>'${event.skill.skill}'</b> is an <b>'${categoryHeading}'</b> Skill. It has been moved under <b>'${(event.skill.category.toLowerCase() == 'other') ? 'Other' : (categoryHeading + ' Skills')}'</b>`;
    if (event) {
      switch (event.skill.category.toLowerCase()) {
        case 'technical':
          this.requisitionSkills.technicalSkills.push(event);
          break;
        case 'operational':
          this.requisitionSkills.operationalSkills.push(event);
          break;
        case 'soft':
          this.requisitionSkills.softSkills.push(event);
          break;
        case 'other':
          this.requisitionSkills.extraSkills.push(event);
          break;
      
        default:
          this.requisitionSkills.extraSkills.push(event);
          break;
      }
      this.alertsAndNotificationsService.showAlert(title, message, 'warning');
    }
  }

}

