import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import {
    CdkDragDrop,
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    moveItemInArray,
    transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap, switchMap } from 'rxjs/operators';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';
import { RequisitionSkill } from 'src/app/core/models/requisitionSkill';
declare var bootbox: any;

@Component({
    selector: 'app-add-edit-candidate-skill',
    templateUrl: './add-edit-candidate-skill.component.html',
    styleUrls: ['./add-edit-candidate-skill.component.css']
})
export class AddEditCandidateSkillComponent implements OnInit {

    @Input() userId;
    @Input() skills;
    @Input() skillType;
    @Input() context;
    @Input() mode;
    @Input() currentSkills;
    @Input() originalSkills;
    @Input() allExistingSkills;
    @Input() allExistingDegrees;
    @Input() allUpdatedDegrees;
    @Input() allExistingFieldOfStudy;
    @Input() allUpdatedFieldOfStudy;
    @Input() allExistingRoles;
    @Input() allUpdatedRoles;
    @Input() allExistingCertificates;
    @Input() allUpdatedCertificates;
    @Input() allExistingJDActionWords;
    @Input() allUpdatedJDActionWords;
    @Input() timePeriods;
    @Output() saveCallback = new EventEmitter<any>();
    @Output() cancelCallback = new EventEmitter<any>();
    @Output() setOtherSkillsCallback = new EventEmitter<any>();
    @Output() setSkillsAsPerCategoryCallback = new EventEmitter<any>();

    skillHeading: any;
    skillsCopy: any;
    selected: any = undefined;
    searchResults: any = [];
    selectedSkillType: any;
    skillTypes: any = [];
    skillsTimePeriods: any = [];
    allSkills: any;
    skillsTypeAheadList: any = [];
    ranges: any = [];
    resetSkillsList: any = [];
    draggable: any;
    addNewSkill: any;
    addNewSkillObj: any;
    isEditSkills: boolean = false;
    viewSkillDetails: boolean = true;
    expandSkills: boolean;

    searchCertificationText: any = '';
    searchCertificates: any;
    searchEducationText: any = '';
    searchEducations: any;
    searchExperienceText: any = '';
    searchExperiences: any;
    searchSkillText: any = '';
    searchSkills: any;
    public certificationsObservable: Observable<any[]>;
    public educationObservable: Observable<any[]>;
    public experienceObservable: Observable<any[]>;
    public skillsObservable:  Observable<any[]>;

    constructor(
        private alertsAndNotificationsService: AlertAndNotificationsService,
        private skillsService: ManageSkillsService
    ) {
    }

    ngOnChanges(changes) {
        if (changes && changes.allExistingSkills && !changes.allExistingSkills.firstChange && changes.allExistingSkills.currentValue && changes.allExistingSkills.previousValue) {
            if (changes.allExistingSkills.currentValue.length != changes.allExistingSkills.previousValue) {
                this.setAllExistingSkills(changes.allExistingSkills.currentValue);
            }
        }

        if (changes && changes.skills && !changes.skills.firstChange && changes.skills.currentValue && changes.skills.previousValue) {
            if (changes.allExistingSkills && (changes.allExistingSkills.currentValue.length != changes.allExistingSkills.previousValue)) {
                this.skillsCopy = this.skills.slice(0,);
            }
        }
    }

    ngOnInit() {

        this.skills.forEach(skill => {
            skill.atleastOrMinOrMax = "atleast",
                skill.plusTo = ""
        });
        this.skillHeading = this._getSkillsHeading();
        this.skillsCopy = angular.copy(this.skills);
        // if (this.mode && this.mode === 'edit') {
        //     this.isEditSkills = true;
        // }
        this._setAllSkills();
        this.selectedSkillType = "education";
        this.skillTypes = [
            { name: "Education", value: "education" },
            { name: "Experience", value: "experience" },
            { name: "Technical Skills", value: "technical" },
            { name: "Operational Skills", value: "operational" },
            { name: "Soft Skills", value: "soft" },
            { name: "Other", value: "other" },
            { name: "Certifications", value: "certifications" },
        ];
        let skills = {
            education: [],
            experience: [],
            technical: [],
            operational: [],
            soft: [],
            other: [],
            certifications: []
        };
        this.currentSkills = angular.copy(skills);
        this.originalSkills = angular.copy(skills);

        this._setExperienceYears();
        this._massageSkillsData();
        this.ranges = [
            { name: "", value: "" },
            { name: "Atleast", value: "atleast" },
            { name: "Min", value: "min" },
            { name: "Max", value: "max" }
        ];
        this.resetSkillsList = [...this.skills];
        this.setSkillsObservable();
    }

    setSkillsObservable() {
        this.certificationsObservable = Observable.create((observer: any) => {
            // Runs on every search 
            observer.next(this.searchCertificationText);
        }).pipe(
            mergeMap((token: string) => this.getSkillsAsObservable(token))
        );

        this.educationObservable = Observable.create((observer: any) => {
            // Runs on every search 
            observer.next(this.searchEducationText);
        }).pipe(
            mergeMap((token: string) => this.getSkillsAsObservable(token))
        );

        this.experienceObservable = Observable.create((observer: any) => {
            // Runs on every search 
            observer.next(this.searchExperienceText);
        }).pipe(
            mergeMap((token: string) => this.getSkillsAsObservable(token))
        );

        this.skillsObservable = Observable.create((observer: any) => {
            // Runs on every search 
            observer.next(this.searchSkillText);
        }).pipe(
            mergeMap((token: string) => this.getSkillsAsObservable(token))
        );
    }

    onSearchCertificationChange(searchCertificationText: string): void {
        this.searchCertificationText = searchCertificationText;
    }

    onSearchEducationChange(searchEducationText: string): void {
        this.searchEducationText = searchEducationText;
    }

    onSearchExperienceChange(searchExperienceText: string): void {
        this.searchExperienceText = searchExperienceText;
    }

    onSearchSkillChange(searchSkillText: string): void {
        this.searchSkillText = searchSkillText;
    }

    _setTypeAheadList(type) {
        switch (type) {
            case 'skills':
                return this.allSkills.allExistingSkills;
            default:
                return this.allSkills.other;
        }
    }

    _getSkillsHeading() {
        switch (this.skillType) {
            case "education":
                return "Education";
            case "experience":
                return "Experience";
            case "technical":
                return "Technical Skills";
            case "operational":
                return "Operational Skills";
            case "soft":
                return "Soft Skills";
            case "other":
                return "Other";
            case "certification":
                return "Certifications"
            default:
                return "Technical Skills";
        }
    }

    _setAllSkills() {
        this.allSkills = {
            education: [],
            department: [],
            experience: [],
            certifications: []
        };

        if (this.allExistingSkills && this.allExistingSkills.length > 0) {
            this.allExistingSkills.forEach(skill => {
                skill.competency = skill.skill;
            });
            this.setAllExistingSkills(this.allExistingSkills);
        } else {
            this.setAllExistingSkills(null);
        }
    }

    _setExperienceYears() {
        this.timePeriods = this.timePeriods ? this.timePeriods : [];
        if (this.skillType === 'technical' || this.skillType === 'operational' || this.skillType === 'soft' || this.skillType === 'other') {
            this.setJDActionWords();
        }
    }

    setJDActionWords() {
        this.skillsTimePeriods = _.cloneDeep(this.timePeriods);
        if(this.allUpdatedJDActionWords) {
            this.allUpdatedJDActionWords.forEach(actionWord => {
                this.skillsTimePeriods.push({ name: actionWord.name + " ", value: { fromYear: "", toYear: "", months: 0, range: "", plusTo: "", isJDActionWord: true, jdAction: actionWord.jdAction } });
            });
        }
    }

    _massageSkillsData() {
        angular.forEach(this.skillTypes, (type, key) => {
            angular.forEach(this.allSkills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
            angular.forEach(this.skills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
            angular.forEach(this.currentSkills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
            angular.forEach(this.originalSkills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
        });
    }

    _setSkillDisplayValue(skill) {
        var skillDisplayValue = "";
        skillDisplayValue = skillDisplayValue + skill.name;
        skill.skillDisplayValue = skillDisplayValue;
    }

    getSkill(searchText) {
        let searchResults = [];
        searchText = searchText.toLowerCase();

        if (this._searchStringHasYearsInfoButNoSkill(searchText, 'skill')) {
            return this._getYearsForSearchString(searchText, 'skill');
        }

        let periodObject;
        let preText = "";
        let isSearchStringHasYears = false;
        let actualSearchText = "";
        angular.forEach(this.skillsTimePeriods, (period, key) => {
            if (searchText.includes(period.name.toLowerCase())) {
                isSearchStringHasYears = true;
                preText = period.name;
                actualSearchText = "";
                actualSearchText = searchText.replace(preText.toLowerCase(), "");
                periodObject = angular.copy(period);
            }
        });

        if (!isSearchStringHasYears) {
            actualSearchText = searchText;
        }

        let validSkills = this.allExistingSkills.filter((skill) => {
            return skill && skill.skill && skill.skill.toLowerCase().includes(actualSearchText);
        });

        if (isSearchStringHasYears && validSkills.length > 0) {
            angular.forEach(validSkills, (skill, key) => {
                let addMonthsText = (periodObject &&  periodObject.value.months > 0) ? this.convertMonthsToYearsText(periodObject.value, skill.skill, (preText + skill.skill)) : '';
                searchResults.push({ displayName: preText + skill.skill, convertedDisplayName:addMonthsText, name: preText + skill.skill, experience: periodObject.value, mandatory: false, favorite: false, id: skill.id, skill: skill });
            });
        } else if (validSkills.length > 0) {
            angular.forEach(validSkills, (skill, key) => {
                searchResults.push({ displayName: skill.skill, name: skill.skill, experience: skill.experience, mandatory: false, favorite: false, id: skill.id, skill: skill });
            });
        }
        return searchResults;
    }

    convertMonthsToYearsText(periodValue, skill, displayName) {
        const wordToNumber = {
            "one": 1,
            "two": 2,
            "three": 3,
            "four": 4,
            "five": 5,
            "six": 6,
            "seven": 7,
            "eight": 8,
            "nine": 9,
            "ten": 10,
            "eleven": 11,
            "twelve": 12
        };
    
        // Regex to capture numeric or word-based months, with optional 'plus'/'+' or "At least/Minimum/Maximum"
        let monthsPattern = /(?:Atleast|Minimum|Maximum)?\s*(\d+|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve)\s*(?:\+|plus)?\s*month(?:s)?/i;
    
        let match = displayName.match(monthsPattern);
        
        if (match) {
            let monthsString = match[1].toLowerCase();
            let months = isNaN(monthsString) ? wordToNumber[monthsString] : parseInt(monthsString);  // Convert word to number or use the numeric value
    
            let prefix = '';
            let suffix = '';
            
            // Check for "Atleast", "Minimum", "Maximum" and append them to the prefix
            if (/Atleast/i.test(displayName)) {
                prefix = 'Atleast ';
            } else if (/Minimum/i.test(displayName)) {
                prefix = 'Minimum ';
            } else if (/Maximum/i.test(displayName)) {
                prefix = 'Maximum ';
            }
    
            // Adjust for "plus" or "+" indicating additional months and update suffix
            if (match[0].includes('plus') || match[0].includes('+')) {
                suffix = ' +'; // Add the plus suffix
            }
    
            let years = (months / 12).toFixed(1);  // Convert months to years and round to 1 decimal place
    
            // Extract the skill from the displayName by removing the matched time period
            let skillName = displayName.replace(match[0], '').trim();
    
            return `${prefix}${years}${suffix} years ${skillName}`;
        } else {
            return '';
        }
    }

    onSkillSelect(e: TypeaheadMatch): void {
        this._addSkill(e);
        this.addNewSkillObj = e.item;
    }

    _addSkill(selectedSkill) {
        if (selectedSkill.id !== undefined) {
            this._setSkillDisplayValue(selectedSkill);
            this.selected = undefined;
        }
    }

    newSkill() {
        this.expandSkills = true;
        this.skills.push(new RequisitionSkill());
    }

    _setNewItem(newItem: any) {
        return newItem && newItem.id ? {
            ...newItem,
            competency: newItem.skill || newItem.name
        } : {
            id: null,
            name: this.addNewSkill,
            atleastOrMinOrMax: '',
            competency: this.addNewSkill,
            mandatory: false,
            favorite: false,
            experience: { years: 0, months: 0 }
        };
    }

    setSkillsCopy(skills) {
        this.skillsCopy = skills.slice(0,);
    }

    onAddSkill() {
        if (this.addNewSkill && this.addNewSkill != '') {
            let newSkill:any = new RequisitionSkill();
            newSkill.displayName = this.addNewSkill;
            newSkill.competency = this.addNewSkill;
            this.setSkills(newSkill);
            let timePeriodObj = this.skillsTimePeriods.filter(timePeriod => (this.addNewSkill.length > timePeriod.name.length) && (this.addNewSkill.includes(timePeriod.name))).sort((a, b) => b.name.length - a.name.length)[0];
            // Check if input text has any of existing skills
            let existingSkillName = timePeriodObj ? this.addNewSkill.replace(timePeriodObj.name, '').trim() : this.addNewSkill;
            let existingSkill = this.allExistingSkills.filter(skill => 
                existingSkillName.toLowerCase() === skill.skill.toLowerCase()
            );
            // Check and convert Months text to years format
            let addMonthsText = (timePeriodObj && timePeriodObj.value.months > 0) ? this.convertMonthsToYearsText(timePeriodObj.value, '', newSkill.displayName) : '';
            newSkill.convertedDisplayName = addMonthsText;

            if(!existingSkill || existingSkill.length === 0) {
                newSkill.skill = {skill: existingSkillName};
                this.setOtherSkillsCallback.emit(newSkill);
            } else {
                if(existingSkill[0].category.toLowerCase() != this.skillType) {
                    newSkill.skill = existingSkill[0];
                    this.setSkillsAsPerCategoryCallback.emit(newSkill);
                } else {
                    this.skills.push(newSkill);
                    this.setSkillsCopy(this.skills);
                }
            }
            this.addNewSkillObj = {};
            this.addNewSkill = '';
        } else {
            this.alertsAndNotificationsService.showAlert('Skill Name Empty', "Enter Skill Name to Add", 'warning');
        }
    }

    onSkillUpdate(skillToUpdate) {
        this._setSkillDisplayValue(skillToUpdate);
        var skillIndex = _.findIndex(this.skills[this.selectedSkillType], (skill: any) => {
            return skillToUpdate.id === skill.id && skillToUpdate.skillDisplayValue === skill.skillDisplayValue;
        });
        this.skills[this.selectedSkillType].splice(skillIndex, 1, skillToUpdate);
    }

    onDeleteSkill(skill) {
        this._deleteSkill(skill);
        if (this.allSkills.allExistingSkills) {
            this.allSkills.allExistingSkills = this.allSkills.allExistingSkills.push(skill);
        }
    }

    _deleteSkill(skillToDelete) {
        _.remove(this.skills[this.selectedSkillType], (skill: any) => {
            return skillToDelete.id == skill.id && skillToDelete.skillDisplayValue == skill.skillDisplayValue;
        });
    }

    onResetSkillsClick(resetType) {
        var message = "";
        if (resetType == "previous") {
            message = "Going back to Requisition Detail of version saved previously. Are you sure to continue?";
        } else if (resetType == 'original') {
            message = "Going back to Requisition Detail of version saved when Requisition was ‘Activated’. Are you sure to continue?";
        }
        bootbox.confirm({
            closeButton: false,
            title: "<div class='alert alert-warning' style='margin-bottom: 0px;'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Warning!</strong></div>",
            message: message,
            buttons: {
                confirm: {
                    label: 'Yes',
                    className: 'btn-success'
                },
                cancel: {
                    label: 'Cancel',
                    className: 'btn-danger'
                }
            },
            callback: (result) => {
                if (result) {
                    this._resetSkill(resetType);
                }
            }
        });
    }

    _resetSkill(resetType) {
        if (resetType == "previous") {
            this.skills = angular.copy(this.currentSkills);
        } else if (resetType == "original") {
            this.skills = angular.copy(this.originalSkills);
        }
    }

    toggleDetailsView() {
        this.viewSkillDetails = !this.viewSkillDetails;
    }

    enter() {
        console.log(this.selected);
        var selectedSkill = { id: 10, name: this.selected, mandatory: false, favorite: false, experience: { years: 0, months: 0 } };
        this._setSkillDisplayValue(selectedSkill);
        this.skills[this.selectedSkillType].push(selectedSkill);
        this.selected = undefined;
    }

    setAllExistingSkills(allSkills) {
        let existingSkillsIds = this.skills ? this.skills.map(skill => { return skill.id }) : [];
        if (this.allExistingSkills) {
            this.allExistingSkills = this.allExistingSkills.filter(skill => {
                if (!existingSkillsIds.includes(skill.id)) {
                    return true;
                }
            });
        }
        if (allSkills) {
            this.allSkills.allExistingSkills = [...this.allExistingSkills];
        } else {
            this.allSkills.allExistingSkills = [];
        }
    }

    editSkills() {
        this.isEditSkills = !this.isEditSkills;
        if (this.skillType === 'certification') {
            this.skills = this.skills.filter(skill => skill.displayName && skill.displayName != '');
            this.skills.forEach(skill => {
                skill = this.setCertificate(skill);
            });
        } else if (this.skillType === 'education') {
            this.skills = this.skills.filter(skill => skill.displayName && skill.displayName != '');
            this.skills.forEach(skill => {
                skill = this.setEducation(skill);
            });
        } else if (this.skillType === 'experience') {
            this.skills = this.skills.filter(skill => skill.displayName && skill.displayName != '');
            this.skills.forEach(skill => {
                skill = this.setExperience(skill);
            });
        } else {
            this.skills = this.skills.filter(skill => skill.displayName && skill.displayName != '');
            this.skills.forEach(skill => {
                skill = this.setSkills(skill);
            });
        }

        if (this.skillsCopy.length === this.skills.length) {
            this.resetSkillsList = [...this.skills];
        } else {
            const deletedSkills = this.skillsCopy.filter(element =>
                !this.skills.some(skill => (skill.id && skill.id === element.id) || (skill.skill === element.skill))
            );
            if (this.skillType != 'certification' && this.skillType != 'education' && this.skillType != 'experience') {
                this.allExistingSkills = [...this.allExistingSkills, ...deletedSkills];
            }
            this.resetSkillsList = [...this.skills];
        }

        if (this.skillType != 'certification' && this.skillType != 'education' && this.skillType != 'experience') {
            this.setAllExistingSkills(this.allExistingSkills);
        }

        if (this.saveCallback) {
            this.saveCallback.emit(this.allExistingSkills);
        }

    }

    deleteSkill(index) {
        this.skills.splice(index, 1);
    }

    onCandidateSkillUpdate(skillIndex, skillToUpdate, skillPriority) {
        if (skillPriority == 'mandatory' && skillToUpdate.favorite == true) {
            skillToUpdate.favorite = false;
        } else if (skillPriority == 'favorite' && skillToUpdate.mandatory == false) {
            skillToUpdate.favorite = true;
        }

        this.skills.splice(skillIndex, 1, skillToUpdate);
    }

    resetSkills() {
        this.skills = [...this.resetSkillsList];
        this.editSkills();
    }

    updateMandatory(skill) {
        skill.mandatory = !skill.mandatory;
        if (skill.mandatory) {
            skill.niceToHave = false;
            skill.preferred = false;
        }
    }

    updateNiceToHave(skill) {
        skill.niceToHave = !skill.niceToHave;
        if (skill.niceToHave) {
            skill.mandatory = false;
            skill.preferred = false;
        }
    }

    updatePreferred(skill) {
        skill.preferred = !skill.preferred;
        if (skill.preferred) {
            skill.mandatory = false;
            skill.niceToHave = false;
        }
    }

    onEditSkill() {
        this.isEditSkills = !this.isEditSkills;
        this.resetSkillsList = [...this.skills];
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(this.skills, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                this.skills,
                event.previousIndex,
                event.currentIndex,
            );
        }
    }

    onRequisitionSkillSelect(e: TypeaheadMatch): void {
        let event: any = e;
        // if (this.skillType === 'education') {
        //     this.newRequisitionSkill.degreeName = event.item.degreeName ? event.item.degreeName : "";
        //     this.newRequisitionSkill.fieldOfStudy = event.item.fieldOfStudy ? event.item.fieldOfStudy : "";
        // } else if (this.skillType === 'experience') {
        //     this.newRequisitionSkill.title = event.title;
        // }
    }
    escapeRegExp(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
    }

    getSkillsAsObservable(token: string): Observable<any> {
        const escapedToken = this.escapeRegExp(token);
        const query = new RegExp(escapedToken, 'i');
        if (this.skillType === 'education') {
            this.searchEducations = [];
            this.searchEducations = this.getEducationTypeAhead(token);
            return of(
                this.searchEducations.filter((state: any) => {
                    return query.test(state.name);
                })
            );
        } else if (this.skillType === 'experience') {
            this.searchExperiences = [];
            this.searchExperiences = this.getExperienceTypeAhead(token);
            return of(
                this.searchExperiences.filter((state: any) => {
                    return query.test(state.name);
                })
            );
        } else if (this.skillType === 'certification') {
            this.searchCertificates = [];
            this.searchCertificates = this.getCertificationTypeAhead(token);
            return of(
                this.searchCertificates.filter((state: any) => {
                    return query.test(state.name);
                })
            );
        } else {
            this.searchSkills = [];
            this.searchSkills = this.getSkill(token);
            return of(
                this.searchSkills.filter((state: any) => {
                    return query.test(state.name);
                })
            );
        }

    }

    getCertificationTypeAhead(searchText) {
        let searchResults = [];
        searchText = searchText.toLowerCase();

        if (this.searchStringHasCertification(searchText)) {
            return this.getCertificatesForSearchString(searchText);
        }

        let searchCertificateObj;
        let preText = "";
        let isSearchStringHasCertificates = false;
        let actualSearchText = "";
        this.allUpdatedCertificates.forEach(certificate => {
            if (searchText.includes(certificate.group.toLowerCase())) {
                isSearchStringHasCertificates = true;
                preText = certificate.group;
                actualSearchText = "";
                actualSearchText = searchText.replace(preText.toLowerCase(), "");
                if (actualSearchText.includes(" in ")) {
                    actualSearchText = actualSearchText.replace(" in ", "");
                } else if (actualSearchText.includes(" in")) {
                    actualSearchText = actualSearchText.replace(" in", "");
                }
                searchCertificateObj = _.cloneDeep(certificate);
            }
        });

        if (!isSearchStringHasCertificates) {
            actualSearchText = searchText;
        }

        let validSkills = this.allUpdatedCertificates.filter((certificate) => {
            return certificate.name.toLowerCase().includes(actualSearchText);
        });

        if (isSearchStringHasCertificates && validSkills.length > 0) {
            validSkills.forEach(certificate => {
                searchResults.push(
                    {
                        displayName: searchCertificateObj.inName + certificate.name,
                        name: searchCertificateObj.inName + certificate.name,
                        experience: 0,
                        mandatory: false,
                        favorite: false,
                        id: certificate.id,
                        degreeName: searchCertificateObj.group,
                        fieldOfStudy: certificate.name
                    });
            });
        } else {
            validSkills.forEach(certificate => {
                searchResults.push(
                    {
                        displayName: certificate.name,
                        name: certificate.name,
                        experience: 0,
                        mandatory: false,
                        favorite: false,
                        id: certificate.id,
                        degreeName: "",
                        fieldOfStudy: certificate.name
                    });
            });
        }
        return searchResults;
    }

    searchStringHasCertification(searchText) {
        let certificatesResult = this.allUpdatedCertificates.filter((certificate) => {
            return certificate.group.toLowerCase().includes(searchText);
        });
        return certificatesResult.length > 0;
    }

    getCertificatesForSearchString(searchText) {
        let certificatesResult = this.allUpdatedCertificates.filter((certificate) => {
            return certificate.group.toLowerCase().includes(searchText);
        });
        let certificateObjectArray = [];
        certificatesResult.forEach(certificate => {
            certificateObjectArray.push({ displayName: certificate.inName, name: certificate.inName });
        })
        return certificateObjectArray;
    }

    setCertificate(certificate) {
        if (certificate.displayName.includes(" in ")) {
            let degreesAndFieldOfStudy = certificate.displayName.split(" in ");
            certificate.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
            certificate.fieldOfStudy = degreesAndFieldOfStudy[1] ? degreesAndFieldOfStudy[1] : "";
        } else if (certificate.displayName.includes(" in")) {
            let degreesAndFieldOfStudy = certificate.displayName.split(" in");
            certificate.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
            certificate.fieldOfStudy = "";
        } else {
            certificate.degreeName = certificate.displayName;
            certificate.fieldOfStudy = "";
        }
    }


    _searchStringHasDegreesInfoButNoFieldOfStudy(searchText) {
        let degreesResult = this.allUpdatedDegrees.filter((degree) => {
            return degree.degreeName.toLowerCase().includes(searchText);
        });
        return degreesResult.length > 0;
    }

    _getDegreesForSearchString(searchText) {
        let degreesResult = this.allUpdatedDegrees.filter((degree) => {
            return degree.degreeName.toLowerCase().includes(searchText);
        });
        let degreesObjectArray = [];
        degreesResult.forEach(degree => {
            degreesObjectArray.push({ displayName: degree.name, name: degree.name });
        })
        return degreesObjectArray;
    }

    getEducationTypeAhead(searchText) {
        let searchResults = [];
        searchText = searchText.toLowerCase();

        if (this._searchStringHasDegreesInfoButNoFieldOfStudy(searchText)) {
            return this._getDegreesForSearchString(searchText);
        }

        let searchDegreeObj;
        let preText = "";
        let isSearchStringHasDegrees = false;
        let actualSearchText = "";
        this.allUpdatedDegrees.forEach(degree => {
            if (searchText.includes(degree.degreeName.toLowerCase()) && !searchText.includes(' or equivalent experience')) {
                isSearchStringHasDegrees = true;
                preText = degree.degreeName;
                actualSearchText = "";
                actualSearchText = searchText.replace(preText.toLowerCase(), "");
                if (actualSearchText.includes(" in ")) {
                    actualSearchText = actualSearchText.replace(" in ", "");
                } else if (actualSearchText.includes(" in")) {
                    actualSearchText = actualSearchText.replace(" in", "");
                }
                searchDegreeObj = _.cloneDeep(degree);
                isSearchStringHasDegrees = true;
            }
        });

        if (!isSearchStringHasDegrees) {
            actualSearchText = searchText;
        }

        let validFieldOfStudy = this.allUpdatedFieldOfStudy.filter((fieldOfStudy) => {
            return fieldOfStudy.fieldOfStudy.toLowerCase().includes(actualSearchText);
        });

        if (isSearchStringHasDegrees && validFieldOfStudy.length > 0) {
            validFieldOfStudy.forEach(fieldOfStudy => {
                searchResults.push(
                    {
                        displayName: searchDegreeObj.name + fieldOfStudy.fieldOfStudy,
                        name: searchDegreeObj.name + fieldOfStudy.fieldOfStudy,
                        experience: 0,
                        mandatory: false,
                        favorite: false,
                        id: fieldOfStudy.id,
                        degreeName: searchDegreeObj.degreeName,
                        fieldOfStudy: fieldOfStudy.fieldOfStudy
                    });
            });
        } else {
            validFieldOfStudy.forEach(fieldOfStudy => {
                searchResults.push(
                    {
                        displayName: fieldOfStudy.fieldOfStudy,
                        name: fieldOfStudy.fieldOfStudy,
                        experience: 0,
                        mandatory: false,
                        favorite: false,
                        id: fieldOfStudy.id,
                        degreeName: "",
                        fieldOfStudy: fieldOfStudy.fieldOfStudy
                    });
            });
        }
        return searchResults;
    }

    setEducation(education) {
        if (education.displayName.includes(" in ")) {
            let degreesAndFieldOfStudy = education.displayName.split(" in ");
            education.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
            education.fieldOfStudy = degreesAndFieldOfStudy[1] ? degreesAndFieldOfStudy[1] : "";
        } else if (education.displayName.includes(" in")) {
            let degreesAndFieldOfStudy = education.displayName.split(" in");
            education.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
            education.fieldOfStudy = "";
        } else {
            education.degreeName = education.displayName;
            education.fieldOfStudy = "";
        }
    }

    getExperienceTypeAhead(searchText) {
        let searchResults = [];
        searchText = searchText.toLowerCase();

        if (this._searchStringHasYearsInfoButNoSkill(searchText, 'role')) {
            return this._getYearsForSearchString(searchText, 'role');
        }

        let periodObject;
        let preText = "";
        let isSearchStringHasYears = false;
        let actualSearchText = "";
        angular.forEach(this.timePeriods, (period, key) => {
            if (searchText.includes(period.name.toLowerCase())) {
                isSearchStringHasYears = true;
                preText = period.name;
                actualSearchText = "";
                actualSearchText = searchText.replace(preText.toLowerCase(), "");
                periodObject = angular.copy(period);
            }
        });

        if (!isSearchStringHasYears) {
            actualSearchText = searchText;
        }

        let validRoles = this.allUpdatedRoles.filter((role) => {
            return role.role.toLowerCase().includes(actualSearchText);
        });

        if (isSearchStringHasYears && validRoles.length > 0) {
            validRoles.forEach(role => {
                searchResults.push(
                    {
                        displayName: preText + role.role,
                        name: preText + role.role,
                        experience: periodObject.value,
                        mandatory: false,
                        favorite: false,
                        id: role.role.id,
                        title: role.role,
                        role: role
                    });
            });
        } else if (validRoles.length > 0) {
            validRoles.forEach(role => {
                searchResults.push(
                    {
                        displayName: role.role,
                        name: role.role,
                        experience: 0,
                        mandatory: false,
                        favorite: false,
                        id: role.role.id,
                        title: role.role,
                        role: role
                    });
            });
        }
        return searchResults;
    }

    _searchStringHasYearsInfoButNoSkill(searchText, type) {
        let yearsResult = [];
        if (type === 'skill') {
            yearsResult = this.skillsTimePeriods.filter((period) => {
                return period.name.toLowerCase().includes(searchText);
            });
        } else {
            yearsResult = this.timePeriods.filter((period) => {
                return period.name.toLowerCase().includes(searchText);
            });
        }
        return yearsResult.length > 0;
    }

    _getYearsForSearchString(searchText, type) {
        let yearsResult = []
        if (type === 'skill') {
            yearsResult = this.skillsTimePeriods.filter((period) => {
                return period.name.toLowerCase().includes(searchText);
            });
        } else {
            yearsResult = this.timePeriods.filter((period) => {
                return period.name.toLowerCase().includes(searchText);
            });
        }
        let yearsObjectArray = [];
        angular.forEach(yearsResult, (year, key) => {
            yearsObjectArray.push({ displayName: year.name, name: year.name });
        });
        return yearsObjectArray;
    }

    setExperience(experience) {
        let timePeriodObj = this.timePeriods.filter(timePeriod => (experience.displayName.length > timePeriod.name.length) && (experience.displayName.includes(timePeriod.name))).sort((a, b) => b.name.length - a.name.length)[0];
        let addMonthsText = (timePeriodObj &&  timePeriodObj.value.months > 0) ? this.convertMonthsToYearsText(timePeriodObj.value, '', experience.displayName) : '';
            experience.convertedDisplayName = addMonthsText;
        if (timePeriodObj) {
            let typeAheadValue = timePeriodObj.value;
            experience.fromYear = typeAheadValue.fromYear;
            experience.toYear = typeAheadValue.toYear;
            experience.atleastOrMinOrMax = typeAheadValue.range;
            experience.plusTo = typeAheadValue.plusTo;
            experience.competency = experience.displayName.replace(timePeriodObj, "");
            experience.title = experience.displayName.replace(timePeriodObj, "");
        } else {
            experience.competency = experience.displayName;
            experience.title = experience.displayName;
            experience.fromYear = "";
            experience.toYear = "";
            experience.atleastOrMinOrMax = "";
            experience.plusTo = "";
        }
    }

    setSkills(skill) {
        let timePeriod = this.skillsTimePeriods.filter(timePeriod => ((skill.displayName.length > timePeriod.length) && skill.displayName.includes(timePeriod)))[0];
        if (timePeriod) {
            let typeAheadValue = timePeriod.value;
            skill.fromYear = typeAheadValue.fromYear;
            skill.toYear = typeAheadValue.toYear;
            skill.atleastOrMinOrMax = typeAheadValue.range;
            skill.plusTo = typeAheadValue.plusTo;
            skill.competency = skill.displayName.replace(timePeriod, "");
            skill.jdActionWord = skill.jdActionWord;
        } else {
            skill.competency = skill.displayName;
            skill.jdActionWord = "";
            skill.fromYear = ""
            skill.toYear = ""
            skill.atleastOrMinOrMax = ""
            skill.plusTo = "";
        }
    }
}
