<div class="row">
    <div class="col-xs-12">
        <h3 style="height:32px; border-color: #e7ebee;" class="skills-container d-flex justify-content-between mt-0">
            <strong>
                <span class="pr-3">{{skillHeading}}</span>
                <span *ngIf="!isEditSkills" class="fas fa-edit pointer d-inline-block text-primary"
                    style="height:30px; font-size: 15px;" (click)="onEditSkill()" tooltip="Edit"
                    container="true"></span>
            </strong>
            <!-- <span
                *ngIf="skillType !== 'education' && skillType !== 'experience' && skillType !== 'certification' && isEditSkills"
                style="border-bottom: 0px; bottom: 5px; width: 70%;" class="position-relative">
                    <input 
                        style="width: 85%;"
                        [(ngModel)]="addNewSkill" 
                        [typeahead]="skillsObservable"
                        [typeaheadAsync]="true"
                        typeaheadOptionField="displayName" 
                        class="d-inline-block mr-3" 
                        placeholder="Enter Skill"
                        (typeaheadOnSelect)="onRequisitionSkillSelect($event)" 
                        [typeaheadOptionsLimit]="8"
                        container="body"
                        [typeaheadScrollable]="true"
                        [typeaheadOptionsInScrollableView]="8"
                        (ngModelChange)="onSearchSkillChange($event)"
                    >
                <button class="btn btn-primary position-relative px-3 py-2" (click)="onAddSkill()"
                    style="bottom: 6px;">Add</button>
            </span> -->
        </h3>
    </div>
</div>
<div class="row candidate-profile-assets">
    <!-- Skills -->
    <div *ngIf="skillType !== 'education' && skillType !== 'experience' && skillType !== 'certification'"
        class="col-xs-12 text-left skills-container">
        <div class="row" cdkDropList [cdkDropListData]="skills" (cdkDropListDropped)="drop($event)">
            <div class="col-xs-12 pl-2" *ngIf="skills.length > 0">
                <div *ngFor="let skill of skills; index as $index; trackBy:$index" cdkDrag
                    [cdkDragDisabled]="isEditSkills" id="skill-{{skillType}}" [cdkDragData]="skill">
                    <div class="d-flex align-items-center pb-2">
                        <div *ngIf="!isEditSkills && skill.competency && skill.competency != ''" class="d-flex pl-3"
                            style="cursor: move;">
                            <!-- <div *ngIf="skill.jdActionWord &&  skill.jdActionWord != ''"
                                class="pr-2 text-capitalize font-bold">{{skill.jdActionWord}}</div>
                            <div *ngIf="skill.atleastOrMinOrMax != '' && (skill.fromYear || skill.toYear)"
                                class="pr-2 text-capitalize font-bold">{{skill.atleastOrMinOrMax}}</div>
                            <div *ngIf="skill.fromYear || skill.toYear" class="">
                                <span class="font-bold pr-1" *ngIf="skill.fromYear">{{skill.fromYear}}</span>
                                <span class="font-bold pr-1"
                                    *ngIf="skill.plusTo != 'to' || skill.toYear">{{skill.plusTo}}</span>
                                <span class="font-bold pr-2" *ngIf="skill.toYear">{{skill.toYear}}</span>
                                <span class="mr-2" *ngIf="skill.displayName.includes('years')">years of experience in</span>
                                <span class="mr-2" *ngIf="!skill.displayName.includes('years')">year of experience in</span>
                            </div> -->
                            <div class="mr-2 font-bold" *ngIf="!skill.convertedDisplayName || skill.convertedDisplayName == ''" [ngClass]="{'text-success other-skill': skill.competency.toLowerCase() == 'other'}">{{skill.competency}}</div>
                            <div class="mr-2 font-bold" *ngIf="skill.convertedDisplayName && skill.convertedDisplayName != ''">{{skill.convertedDisplayName}}</div>
                            <div *ngIf="skill.mandatory || skill.favorite || skill.niceToHave || skill.preferred" class="pr-2">
                                <span class="pr-2 fas fa-asterisk color-red" *ngIf="skill.mandatory" tooltip="Mandatory"
                                    container="true"></span>
                                <span class="pr-2 fas fa-heart text-danger" *ngIf="skill.favorite" tooltip="Favorite"
                                    container="true"></span>
                                <span class="pr-2 fas fa-check-circle text-success" *ngIf="skill.preferred"
                                    tooltip="Preferred" container="true"></span>
                                <span class="pr-2 fas fa-thumbs-up text-primary" *ngIf="skill.niceToHave"
                                    tooltip="Nice to have" container="true"></span>
                            </div>
                            <div class="pr-2" *ngIf="skills.length - 1 > $index">
                                <span class="text-muted font-bold" tooltip="Priority" container="true">{{skill.priority ? '(OR)' : '(AND)'}}</span>
                            </div>
                        </div>
                        <div *ngIf="isEditSkills"
                            class="d-flex justify-content-around mt-2 candidate-skills-item pl-1 col-xs-11">
                                <input 
                                    style="font-size: 15px;width:72%"
                                    [(ngModel)]="skill.displayName" 
                                    [typeahead]="skillsObservable"
                                    [typeaheadAsync]="true"
                                    typeaheadOptionField="displayName" 
                                    class="d-inline-block mr-3" 
                                    placeholder="Enter Skill"
                                    (typeaheadOnSelect)="onRequisitionSkillSelect($event)" 
                                    [typeaheadOptionsLimit]="8"
                                    container="body"
                                    [typeaheadScrollable]="true"
                                    [typeaheadOptionsInScrollableView]="8"
                                    (ngModelChange)="onSearchSkillChange($event)"
                                    (keyup.enter)="newSkill()"
                                >
                            <span class="pt-1 d-flex justify-content-end mt-2 mandatory-favorite-icon">
                                <span *ngIf="!skill.niceToHave && !skill.preferred && skill.mandatory"
                                    class="cursor-pointer fas fa-asterisk color-red pr-2 pt-1" 
                                    tooltip="Mandatory" container="true"
                                    (click)="updateMandatory(skill)" style="font-size: 14px;">
                                </span>
                                <span *ngIf="!skill.niceToHave && !skill.mandatory && !skill.preferred" class="cursor-pointer" tooltip="Mandatory" container="true" (click)="updateMandatory(skill)">
                                    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                        <path class="cls-asterisk" fill="red"
                                            d="m28.89 20.91-5-2.91 4.87-2.86a3.1 3.1 0 0 0 1.14-1.08 3 3 0 0 0-4.09-4.15L21 12.76V7a3 3 0 0 0-6 0v5.76l-4.85-2.85a3 3 0 1 0-3 5.18l5 2.91-4.95 2.86a3.1 3.1 0 0 0-1.14 1.08 3 3 0 0 0 4.09 4.14L15 23.24v5.66a3 3 0 0 0 2 2.94A3 3 0 0 0 21 29v-5.76l4.85 2.85a3 3 0 1 0 3-5.18ZM28.24 24a1 1 0 0 1-1.37.36L19 19.75V29a1 1 0 0 1-2 0v-9.25l-7.87 4.61a1 1 0 0 1-1-1.72L16 18l-7.9-4.64a1 1 0 1 1 1-1.72l7.9 4.61V7a1 1 0 0 1 2 0v9.25l7.87-4.62a1 1 0 0 1 1 1.72L20 18l7.9 4.64a1 1 0 0 1 .34 1.36" />
                                        <path fill="none" d="M0 0h36v36H0z" />
                                    </svg>
                                </span>
                                <span class="cursor-pointer text-danger fa-heart pr-2 mr-1 pt-1"
                                    [ngClass]="{'fas': skill.favorite, 'far': !skill.favorite}"
                                    (click)="skill.favorite = !skill.favorite" tooltip="Favorite" container="true">
                                </span>
                                <span *ngIf="!skill.mandatory && !skill.niceToHave"
                                    class="cursor-pointer text-success fa-check-circle pr-2 mr-1 pt-1"
                                    [ngClass]="{'fas': skill.preferred, 'far': !skill.preferred}"
                                    (click)="updatePreferred(skill)" tooltip="Preferred" container="true">
                                </span>
                                <span *ngIf="!skill.mandatory && !skill.preferred"
                                    class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-2 mr-1 pt-1"
                                    [ngClass]="{'fas': skill.niceToHave, 'far': !skill.niceToHave}"
                                    (click)="updateNiceToHave(skill)" tooltip="Nice to have" container="true">
                                </span>
                                <span class="fas fa-times text-danger cursor-pointer delete-skill"
                                    tooltip="Delete Skill" (click)="deleteSkill($index)"></span>
                            </span>
                        </div>
                        <div *ngIf="(skills.length - 1 > $index) && isEditSkills" container="true"
                            tooltip="{{isEditSkills ? 'Click to change priority' : ''}}"
                            [ngClass]="{'mt-2': isEditSkills, 'mt-0': !isEditSkills}"
                            class="cursor-pointer col-xs-1 mt-2 d-flex align-items-center justify-content-start px-0 text-muted font-bold">
                            <span *ngIf="skill.priority" (click)="skill.priority = !skill.priority"
                                [ngClass]="{'pointer-events-none': !isEditSkills}">(OR)</span>
                            <span *ngIf="!skill.priority" (click)="skill.priority = !skill.priority"
                                [ngClass]="{'pointer-events-none': !isEditSkills}">(AND)</span>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="skills.length == 0 && !isEditSkills">
                <strong><span class="asset-value pl-3" style="font-weight: 500;">No skills available.</span></strong>
            </div>
            <div class="col-xs-6" *ngIf="isEditSkills">
                <span class="text-primary d-inline-block m-3 cursor-pointer" (click)="newSkill()"><span
                        class="fas fa-plus"></span> Add Skill</span>
            </div>
            <div *ngIf="isEditSkills" class=" col-xs-12 d-flex justify-content-end mb-2 mt-5">
                <button class="btn btn-danger mr-3 skill-button" (click)="resetSkills()">Cancel</button>
                <button class="btn btn-primary skill-button" (click)="editSkills()">Save</button>
            </div>
        </div>
    </div>

    <!-- Education -->
    <div *ngIf="skillType === 'education'" class="col-xs-12 text-left skills-container">
        <div *ngIf="skills.length > 0">
            <div *ngFor="let education of skills; index as $index; trackBy:$index"
                class="d-flex align-items-center pb-2">
                <div *ngIf="!isEditSkills && education.degreeName && education.degreeName != ''" class="d-flex pl-3">
                    <div class="mr-2 font-bold">{{education.degreeName}}</div>
                    <div class="mr-1" *ngIf="education.fieldOfStudy && education.fieldOfStudy != ''">in</div>
                    <div class="mr-2 font-bold" *ngIf="education.fieldOfStudy && education.fieldOfStudy != ''">
                        {{education.fieldOfStudy}}</div>
                    <div *ngIf="education.mandatory || education.favorite || education.niceToHave || education.preferred" class="pr-2">
                        <span class="pr-2 fas fa-asterisk color-red" *ngIf="education.mandatory" tooltip="Mandatory"
                                container="true"></span>
                        <span class="pr-2 fas fa-heart text-danger" *ngIf="education.favorite" tooltip="Favorite"
                            container="true"></span>
                        <span class="pr-2 fas fa-check-circle text-success" *ngIf="education.preferred"
                            tooltip="Preferred" container="true"></span>
                        <span class="pr-2 fas fa-thumbs-up text-primary" *ngIf="education.niceToHave"
                            tooltip="Nice to have" container="true"></span>
                    </div>
                    <div class="pr-2" *ngIf="skills.length - 1 > $index">
                        <span class="text-muted font-bold" tooltip="Priority" container="true">{{education.priority ? '(OR)' : '(AND)'}}</span>
                    </div>
                </div>
                <div *ngIf="isEditSkills && context==='job'" class="d-flex justify-content-start mt-2 pl-1 col-xs-11">
                        <input 
                            style="font-size: 15px;width:75%"
                            [(ngModel)]="education.displayName" 
                            [typeahead]="educationObservable"
                            [typeaheadAsync]="true"
                            typeaheadOptionField="displayName" 
                            class="d-inline-block mr-3" 
                            placeholder="Enter Education"
                            (typeaheadOnSelect)="onRequisitionSkillSelect($event)" 
                            [typeaheadOptionsLimit]="8"
                            container="body"
                            [typeaheadScrollable]="true"
                            [typeaheadOptionsInScrollableView]="8"
                            (ngModelChange)="onSearchEducationChange($event)"
                            (keyup.enter)="newSkill()"
                        >
                    <span class="text-primary pt-1 d-flex justify-content-end mandatory-favorite-icon">
                        <span *ngIf="!education.niceToHave && !education.preferred && education.mandatory"
                            class="cursor-pointer fas fa-asterisk color-red pr-2 pt-1" 
                            tooltip="Mandatory" container="true"
                            (click)="updateMandatory(education)" style="font-size: 14px;">
                        </span>
                        <span *ngIf="!education.niceToHave && !education.mandatory && !education.preferred" class="cursor-pointer" tooltip="Mandatory" container="true" (click)="updateMandatory(education)">
                            <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                <path class="cls-asterisk" fill="red"
                                    d="m28.89 20.91-5-2.91 4.87-2.86a3.1 3.1 0 0 0 1.14-1.08 3 3 0 0 0-4.09-4.15L21 12.76V7a3 3 0 0 0-6 0v5.76l-4.85-2.85a3 3 0 1 0-3 5.18l5 2.91-4.95 2.86a3.1 3.1 0 0 0-1.14 1.08 3 3 0 0 0 4.09 4.14L15 23.24v5.66a3 3 0 0 0 2 2.94A3 3 0 0 0 21 29v-5.76l4.85 2.85a3 3 0 1 0 3-5.18ZM28.24 24a1 1 0 0 1-1.37.36L19 19.75V29a1 1 0 0 1-2 0v-9.25l-7.87 4.61a1 1 0 0 1-1-1.72L16 18l-7.9-4.64a1 1 0 1 1 1-1.72l7.9 4.61V7a1 1 0 0 1 2 0v9.25l7.87-4.62a1 1 0 0 1 1 1.72L20 18l7.9 4.64a1 1 0 0 1 .34 1.36" />
                                <path fill="none" d="M0 0h36v36H0z" />
                            </svg>
                        </span>
                        <span class="cursor-pointer text-danger fa-heart pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': education.favorite, 'far': !education.favorite}"
                            (click)="education.favorite = !education.favorite" tooltip="Favorite" container="true">
                        </span>
                        <span *ngIf="!education.mandatory && !education.niceToHave"
                            class="cursor-pointer text-success fa-check-circle pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': education.preferred, 'far': !education.preferred}"
                            (click)="updatePreferred(education)" tooltip="Preferred" container="true">
                        </span>
                        <span *ngIf="!education.mandatory && !education.preferred"
                            class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': education.niceToHave, 'far': !education.niceToHave}"
                            (click)="updateNiceToHave(education)" tooltip="Nice to have" container="true">
                        </span>
                        <span class="fas fa-times text-danger cursor-pointer delete-skill" tooltip="Delete Education"
                            (click)="deleteSkill($index)"></span>
                    </span>
                </div>
                <div *ngIf="(skills.length - 1 > $index) && isEditSkills" container="true"
                    tooltip="{{isEditSkills ? 'Click to change priority' : ''}}"
                    class="cursor-pointer col-xs-1 d-flex align-items-center justify-content-start px-0 text-muted font-bold"
                    [ngClass]="{'mt-2': isEditSkills, 'mt-0': !isEditSkills}">
                    <span *ngIf="education.priority" (click)="education.priority = !education.priority"
                        [ngClass]="{'pointer-events-none': !isEditSkills}">(OR)</span>
                    <span *ngIf="!education.priority" (click)="education.priority = !education.priority"
                        [ngClass]="{'pointer-events-none': !isEditSkills}">(AND)</span>
                </div>
            </div>
        </div>
        <div *ngIf="skills.length == 0 && !isEditSkills">
            <strong><span class="asset-value pl-3" style="font-weight: 500;">No Education available.</span></strong>
        </div>
        <div class="col-xs-6" *ngIf="isEditSkills">
            <span class="text-primary d-inline-block m-3 cursor-pointer" (click)="newSkill()"><span
                    class="fas fa-plus"></span> Add Education</span>
        </div>
        <div *ngIf="isEditSkills" class="d-flex justify-content-end mb-2">
            <button class="btn btn-danger mr-3 skill-button" (click)="resetSkills()">Cancel</button>
            <button class="btn btn-primary skill-button" (click)="editSkills()">Save</button>
        </div>
    </div>

    <!-- Experience -->
    <div *ngIf="skillType === 'experience'" class="col-xs-12 text-left skills-container">
        <div *ngIf="skills.length > 0">
            <div *ngFor="let experience of skills; index as $index; trackBy: $index"
                class="d-flex align-items-center pb-2">
                <div *ngIf="!isEditSkills && experience.title && experience.title != ''" class="d-flex pl-3">
                    <!-- <div *ngIf="experience.atleastOrMinOrMax != '' && (experience.fromYear || experience.toYear)"
                        class="pr-2 text-capitalize font-bold">{{experience.atleastOrMinOrMax}}</div>
                    <div *ngIf="experience.fromYear || experience.toYear" class="">
                        <span class="font-bold pr-1">{{experience.fromYear}}</span>
                        <span class="font-bold pr-1"
                            *ngIf="experience.plusTo != 'to' || experience.toYear">{{experience.plusTo}}</span>
                        <span class="font-bold pr-2">{{experience.toYear}}</span>
                        <span class="mr-2" *ngIf="experience.displayName.includes('years')">years of experience in</span>
                        <span class="mr-2" *ngIf="!experience.displayName.includes('years')">year of experience in</span>
                    </div> -->
                    <div *ngIf="!experience.convertedDisplayName || experience.convertedDisplayName == ''" class="mr-2 font-bold">{{experience.title}}</div>
                    <div *ngIf="experience.convertedDisplayName && experience.convertedDisplayName != ''" class="mr-2 font-bold">{{experience.convertedDisplayName}}</div>
                    <div *ngIf="experience.mandatory || experience.favorite || experience.niceToHave || experience.preferred" class="pr-2">
                        <span class="pr-2 fas fa-asterisk color-red" *ngIf="experience.mandatory" tooltip="Mandatory"
                            container="true"></span>
                        <span class="pr-2 fas fa-heart text-danger" *ngIf="experience.favorite" tooltip="Favorite"
                            container="true"></span>
                        <span class="pr-2 fas fa-check-circle text-success" *ngIf="experience.preferred"
                            tooltip="Preferred" container="true"></span>
                        <span class="pr-2 fas fa-thumbs-up text-primary" *ngIf="experience.niceToHave"
                            tooltip="Nice to have" container="true"></span>
                    </div>
                    <div class="pr-2" *ngIf="skills.length - 1 > $index">
                        <span class="text-muted font-bold" tooltip="Priority" container="true">{{experience.priority ? '(OR)' : '(AND)'}}</span>
                    </div>
                </div>
                <div *ngIf="isEditSkills && context==='job'" class="d-flex justify-content-around mt-2 col-xs-11 pl-1">
                        <input 
                            style="font-size: 15px;width:75%"
                            [(ngModel)]="experience.displayName" 
                            [typeahead]="experienceObservable"
                            [typeaheadAsync]="true"
                            typeaheadOptionField="displayName" 
                            class="d-inline-block mr-3" 
                            placeholder="Enter Experience"
                            (typeaheadOnSelect)="onRequisitionSkillSelect($event)" 
                            [typeaheadOptionsLimit]="8"
                            container="body"
                            [typeaheadScrollable]="true"
                            [typeaheadOptionsInScrollableView]="8"
                            (ngModelChange)="onSearchExperienceChange($event)"
                            (keyup.enter)="newSkill()"
                        >
                    <span class="text-primary d-flex justify-content-end align-items-center mandatory-favorite-icon">
                        <span *ngIf="!experience.niceToHave && !experience.preferred && experience.mandatory"
                            class="cursor-pointer fas fa-asterisk color-red pr-2 pt-1" 
                            tooltip="Mandatory" container="true"
                            (click)="updateMandatory(experience)" style="font-size: 14px;">
                        </span>
                        <span *ngIf="!experience.niceToHave && !experience.mandatory && !experience.preferred" class="cursor-pointer pt-3" tooltip="Mandatory" container="true" (click)="updateMandatory(experience)">
                            <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                <path class="cls-asterisk" fill="red"
                                    d="m28.89 20.91-5-2.91 4.87-2.86a3.1 3.1 0 0 0 1.14-1.08 3 3 0 0 0-4.09-4.15L21 12.76V7a3 3 0 0 0-6 0v5.76l-4.85-2.85a3 3 0 1 0-3 5.18l5 2.91-4.95 2.86a3.1 3.1 0 0 0-1.14 1.08 3 3 0 0 0 4.09 4.14L15 23.24v5.66a3 3 0 0 0 2 2.94A3 3 0 0 0 21 29v-5.76l4.85 2.85a3 3 0 1 0 3-5.18ZM28.24 24a1 1 0 0 1-1.37.36L19 19.75V29a1 1 0 0 1-2 0v-9.25l-7.87 4.61a1 1 0 0 1-1-1.72L16 18l-7.9-4.64a1 1 0 1 1 1-1.72l7.9 4.61V7a1 1 0 0 1 2 0v9.25l7.87-4.62a1 1 0 0 1 1 1.72L20 18l7.9 4.64a1 1 0 0 1 .34 1.36" />
                                <path fill="none" d="M0 0h36v36H0z" />
                            </svg>
                        </span>
                        <span class="cursor-pointer text-danger fa-heart pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': experience.favorite, 'far': !experience.favorite}"
                            (click)="experience.favorite = !experience.favorite" tooltip="Favorite" container="true">
                        </span>
                        <span *ngIf="!experience.mandatory && !experience.niceToHave"
                            class="cursor-pointer text-success fa-check-circle pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': experience.preferred, 'far': !experience.preferred}"
                            (click)="updatePreferred(experience)" tooltip="Preferred" container="true">
                        </span>
                        <span *ngIf="!experience.mandatory && !experience.preferred"
                            class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': experience.niceToHave, 'far': !experience.niceToHave}"
                            (click)="updateNiceToHave(experience)" tooltip="Nice to have" container="true">
                        </span>
                        <span class="fas fa-times text-danger cursor-pointer delete-skill" tooltip="Delete Experience"
                            (click)="deleteSkill($index)"></span>
                    </span>
                </div>
                <div *ngIf="(skills.length - 1 > $index) && isEditSkills" container="true"
                    tooltip="{{isEditSkills ? 'Click to change priority' : ''}}"
                    class="cursor-pointer col-xs-1 d-flex align-items-center justify-content-start px-0 text-muted font-bold"
                    [ngClass]="{'mt-2 pt-1': isEditSkills, 'mt-0': !isEditSkills}">
                    <span *ngIf="experience.priority" (click)="experience.priority = !experience.priority"
                        [ngClass]="{'pointer-events-none': !isEditSkills}">(OR)</span>
                    <span *ngIf="!experience.priority" (click)="experience.priority = !experience.priority"
                        [ngClass]="{'pointer-events-none': !isEditSkills}">(AND)</span>
                </div>
            </div>
        </div>
        <div *ngIf="skills.length == 0 && !isEditSkills">
            <strong><span class="asset-value pl-3" style="font-weight: 500;">No Experience available.</span></strong>
        </div>
        <div class="col-xs-6" *ngIf="isEditSkills">
            <span class="text-primary d-inline-block m-3 cursor-pointer" (click)="newSkill()"><span
                    class="fas fa-plus"></span> Add Experience</span>
        </div>
        <div *ngIf="isEditSkills" class="d-flex justify-content-end">
            <button class="btn btn-danger mr-3 skill-button" (click)="resetSkills()">Cancel</button>
            <button class="btn btn-primary skill-button" (click)="editSkills()">Save</button>
        </div>
    </div>

    <!-- Certification -->
    <div *ngIf="skillType === 'certification'" class="col-xs-12 text-left skills-container">
        <div *ngFor="let certification of skills; index as $index; trackBy:$index">
            <div *ngIf="skills.length > 0" class="d-flex align-items-center pb-2">
                <div *ngIf="!isEditSkills && certification.degreeName && certification.degreeName != ''"
                    class="d-flex pl-3">
                    <div class="mr-2 font-bold">{{certification.degreeName}}</div>
                    <div class="mr-1" *ngIf="certification.fieldOfStudy && certification.fieldOfStudy != ''">in</div>
                    <div class="mr-2 font-bold"
                        *ngIf="certification.fieldOfStudy && certification.fieldOfStudy != ''">
                        {{certification.fieldOfStudy}}</div>
                    <div *ngIf="certification.mandatory || certification.favorite || certification.niceToHave || certification.preferred"
                        class="pr-2">
                        <span class="pr-2 fas fa-asterisk color-red" *ngIf="certification.mandatory" tooltip="Mandatory"
                            container="true"></span>
                        <span class="pr-2 fas fa-heart text-danger" *ngIf="certification.favorite" tooltip="Favorite"
                            container="true"></span>
                        <span class="pr-2 fas fa-check-circle text-success" *ngIf="certification.preferred"
                            tooltip="Preferred" container="true"></span>
                        <span class="pr-2 fas fa-thumbs-up text-primary" *ngIf="certification.niceToHave"
                            tooltip="Nice to have" container="true"></span>
                    </div>
                    <div class="pr-2" *ngIf="skills.length - 1 > $index">
                        <span class="text-muted font-bold" tooltip="Priority" container="true">{{certification.priority ? '(OR)' : '(AND)'}}</span>
                    </div>
                </div>
                <div *ngIf="isEditSkills && context==='job'" class="d-flex justify-content-start mt-2 col-xs-11 pl-1">
                    <input 
                        style="font-size: 15px;width:75%"
                        [(ngModel)]="certification.displayName" 
                        [typeahead]="certificationsObservable"
                        [typeaheadAsync]="true"
                        typeaheadOptionField="displayName" 
                        class="d-inline-block mr-3" 
                        placeholder="Enter Certification"
                        (typeaheadOnSelect)="onRequisitionSkillSelect($event)" 
                        [typeaheadOptionsLimit]="8"
                        container="body"
                        [typeaheadScrollable]="true"
                        [typeaheadOptionsInScrollableView]="8"
                        (ngModelChange)="onSearchCertificationChange($event)"
                        (keyup.enter)="newSkill()"
                    >
                    <span class="text-primary pt-1 d-flex justify-content-end mandatory-favorite-icon">
                        <span *ngIf="!certification.niceToHave && !certification.priority && certification.mandatory"
                            class="cursor-pointer fas fa-asterisk color-red pr-2 pt-1" 
                            tooltip="Mandatory" container="true"
                            (click)="updateMandatory(certification)" style="font-size: 14px;">
                        </span>
                        <span *ngIf="!certification.niceToHave && !certification.mandatory && !certification.priority" class="cursor-pointer" tooltip="Mandatory" container="true" (click)="updateMandatory(certification)">
                            <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                <path class="cls-asterisk" fill="red"
                                    d="m28.89 20.91-5-2.91 4.87-2.86a3.1 3.1 0 0 0 1.14-1.08 3 3 0 0 0-4.09-4.15L21 12.76V7a3 3 0 0 0-6 0v5.76l-4.85-2.85a3 3 0 1 0-3 5.18l5 2.91-4.95 2.86a3.1 3.1 0 0 0-1.14 1.08 3 3 0 0 0 4.09 4.14L15 23.24v5.66a3 3 0 0 0 2 2.94A3 3 0 0 0 21 29v-5.76l4.85 2.85a3 3 0 1 0 3-5.18ZM28.24 24a1 1 0 0 1-1.37.36L19 19.75V29a1 1 0 0 1-2 0v-9.25l-7.87 4.61a1 1 0 0 1-1-1.72L16 18l-7.9-4.64a1 1 0 1 1 1-1.72l7.9 4.61V7a1 1 0 0 1 2 0v9.25l7.87-4.62a1 1 0 0 1 1 1.72L20 18l7.9 4.64a1 1 0 0 1 .34 1.36" />
                                <path fill="none" d="M0 0h36v36H0z" />
                            </svg>
                        </span>
                        <span class="cursor-pointer text-danger fa-heart pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': certification.favorite, 'far': !certification.favorite}"
                            (click)="certification.favorite = !certification.favorite" tooltip="Favorite"
                            container="true">
                        </span>
                        <span *ngIf="!certification.mandatory && !certification.niceToHave"
                            class="cursor-pointer text-success fa-check-circle pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': certification.preferred, 'far': !certification.preferred}"
                            (click)="certification.preferred = !certification.preferred" tooltip="Preferred" container="true">
                        </span>
                        <span *ngIf="!certification.mandatory && !certification.priority"
                            class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': certification.niceToHave, 'far': !certification.niceToHave}"
                            (click)="updateNiceToHave(certification)" tooltip="Nice to have" container="true">
                        </span>
                        <span class="fas fa-times text-danger cursor-pointer delete-skill"
                            tooltip="Delete Certification" (click)="deleteSkill($index)"></span>
                    </span>
                </div>
                <div *ngIf="(skills.length - 1 > $index) && isEditSkills" container="true"
                    tooltip="Click to change priority"
                    class="cursor-pointer col-xs-1 mt-2 d-flex align-items-center justify-content-start px-0 text-muted font-bold">
                    <span *ngIf="certification.priority"
                        (click)="certification.priority = !certification.priority">(OR)</span>
                    <span *ngIf="!certification.priority"
                        (click)="certification.priority = !certification.priority">(AND)</span>
                </div>
            </div>
        </div>
        <div *ngIf="skills.length == 0 && !isEditSkills">
            <strong><span class="asset-value pl-3" style="font-weight: 500;">No Certification available.</span></strong>
        </div>
        <div class="col-xs-6" *ngIf="isEditSkills">
            <span class="text-primary d-inline-block m-3 cursor-pointer" (click)="newSkill()"><span
                    class="fas fa-plus"></span> Add Certification</span>
        </div>
        <div *ngIf="isEditSkills" class="d-flex justify-content-end mt-4">
            <button class="btn btn-danger mr-3 skill-button" (click)="resetSkills()">Cancel</button>
            <button class="btn btn-primary skill-button" (click)="editSkills()">Save</button>
        </div>
    </div>
</div>

<ng-template #addEditCandidateSkill let-item="item" let-index="index">
    <div class="row d-flex">
        <div class="mr-3 radio my-0">
            <input type="radio" [(ngModel)]="item.mandatory" id="candidateSkillMandatory" name="candidateSkill"
                (change)="onCandidateSkillUpdate(index, item, 'mandatory')" [value]="true">
            <label for="candidateSkillMandatory">Mandatory Skill</label>
        </div>
        <div class="radio mt-1 mb-0">
            <input type="radio" [(ngModel)]="item.mandatory" id="candidateSkillFavorite" name="candidateSkill"
                (change)="onCandidateSkillUpdate(index, item, 'favorite')" [value]="false">
            <label for="candidateSkillFavorite">Favorite Skill</label>
        </div>
    </div>
</ng-template>